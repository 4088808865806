<template>
  <div class="hazard-factor-standard-manage">
    <base-headline title="标准项目" />
    <div style="display: flex;">
      <div style="flex:1"></div>
      <div style="flex:1"></div>
      <!-- <zwx-scrollbar-y style="height: 100%">
                <el-tree class="filter-tree" empty-text="暂无数据" :data="gbzOhsSuspectedCodeNoTreeList" :props="defaultProps3" show-checkbox @check-change="handleCheckChange3" accordion node-key="codeNo" ref="tree3" :filter-node-method="filterNodeMethod3" :expand-on-click-node="true" :default-expanded-keys="checkRidArr3" :check-strictly="isCheckStrictly3">
                  <div class="custom-tree-node" slot-scope="{ node, data }" style="display: flex; justify-content: space-between; width: 100%">
                    <div style="display: flex">
                      <div class="name-hidden">{{ node.label }}</div>
                    </div>
                  </div>
                </el-tree>
              </zwx-scrollbar-y> -->
    </div>
    <!-- <el-input placeholder="" style="width: 270px !important; margin-right: 10px !important" class="zwx-input" :ref="searchRef3" v-model="searchInput3">
                <i slot="suffix" class="el-input__icon el-icon-search"></i>
              </el-input> -->
    <div class="multi-select-main" v-show="this.hazardShow">
      <!-- 左侧危害因素树形 -->
      <div class="left">
        <div class="search-row">
          <el-input placeholder="请输入查询" style="width:100% !important" class="zwx-input" :ref="searchRef" v-model="searchInput">
            <i slot="suffix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div class="options">
          <zwx-scrollbar-y style="height: 600px;" :ref="treeScrollbarRef">
            <el-tree :data="showDataList" :ref="treeRef" :filter-node-method="filterNodeMethod" node-key="rid" children="subList" @node-click="factorClick" accordion>
              <div class="custom-tree-node" slot-scope="{ node, data }" style="display:flex;justify-content: space-between;align-items:center;width:100%">
                <div class="hazard-name-hidden">{{ node.label }}</div>
                <img src="@/assets/images/system/ic_ywh.png" style="width:55px;height:18px;margin: 0 10px;" />
                <div :title="data.cas" style="padding-right:20px;font-size:14px;flex:1;text-align:right;">{{ data.cas }}</div>
              </div>
            </el-tree>
          </zwx-scrollbar-y>
        </div>
      </div>
      <!-- 右侧已选 -->
      <div class="right">
        <div style="margin:10px;">
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="add">添加检测项目</el-button>
        </div>

        <zwx-scrollbar-y style="height: 600px;" :ref="checkScrollbarRef">
          <div class="add-new-job-css">
            <el-form class="zwx-form edit-form" key="msForm" :model="msForm" :rules="rules" ref="msForm" label-position="right" @submit.native.prevent>
              <el-table ref="msForm" class="zwx-table" style="width: 100%;" height="363" row-key="rid" :data="msForm.showCheckDataList" border stripe tooltip-effect="light">
                <el-table-column label="检测项目编码" width="150" header-align="center" align="left">
                  <template slot-scope="scope">
                    <el-form-item :prop="'showCheckDataList.' + scope.$index + '.hazardsNameDetail'" :rules="rules.hazardsNameDetail">
                      <el-input class="zwx-input" v-model.trim="scope.row.hazardsNameDetail" placeholder="请输入" maxlength="50" clearable @blur="hazardsNameDetailBlur(scope.row)" />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="检测项目编名称" width="150" header-align="center" align="left">
                  <template slot-scope="scope">
                    <el-form-item :prop="'showCheckDataList.' + scope.$index + '.hazardsNameDetail'" :rules="rules.hazardsNameDetail">
                      <el-input class="zwx-input" v-model.trim="scope.row.hazardsNameDetail" placeholder="请输入" maxlength="50" clearable @blur="hazardsNameDetailBlur(scope.row)" />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="检测项目编码" width="150" header-align="center" align="left">
                  <template slot-scope="scope">
                    <el-form-item :prop="'showCheckDataList.' + scope.$index + '.hazardsNameDetail'" :rules="rules.hazardsNameDetail">
                      <el-input class="zwx-input" v-model.trim="scope.row.hazardsNameDetail" placeholder="请输入" maxlength="50" clearable @blur="hazardsNameDetailBlur(scope.row)" />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="PC-TWA" width="150" header-align="center" align="left">
                  <template slot-scope="scope">
                    <el-form-item :prop="'showCheckDataList.' + scope.$index + '.hazardsNameDetail'" :rules="rules.hazardsNameDetail">
                      <el-input class="zwx-input" v-model.trim="scope.row.hazardsNameDetail" placeholder="请输入" maxlength="50" clearable @blur="hazardsNameDetailBlur(scope.row)" />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="PC-TWA" width="150" header-align="center" align="left">
                  <template slot-scope="scope">
                    <el-form-item :prop="'showCheckDataList.' + scope.$index + '.hazardsNameDetail'" :rules="rules.hazardsNameDetail">
                      <el-input class="zwx-input" v-model.trim="scope.row.hazardsNameDetail" placeholder="请输入" maxlength="50" clearable @blur="hazardsNameDetailBlur(scope.row)" />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="PC-TWA" width="150" header-align="center" align="left">
                  <template slot-scope="scope">
                    <el-form-item :prop="'showCheckDataList.' + scope.$index + '.hazardsNameDetail'" :rules="rules.hazardsNameDetail">
                      <el-input class="zwx-input" v-model.trim="scope.row.hazardsNameDetail" placeholder="请输入" maxlength="50" clearable @blur="hazardsNameDetailBlur(scope.row)" />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="操作" header-align="left" align="left">
                  <template slot-scope="scope">
                    <el-button class="zwx-button zwx-button-text-26" type="danger" @click="showCheckDataListDelete(scope.$index)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </div>
        </zwx-scrollbar-y>
      </div>
    </div>
    <!-- <el-form class='zwx-form' key='msForm' :model='msForm' ref='msForm' label-position='right' @submit.native.prevent>
        <div class='edit-form'>
          <el-form-item label='类别：' prop='hazardsSortLists' label-width='50px'>
            <el-checkbox-group class='zwx-checkbox-group' v-model='msForm.hazardsSortLists'>
              <el-checkbox class='zwx-checkbox' :label='1'>粉尘</el-checkbox>
              <el-checkbox class='zwx-checkbox' :label='2'>化学</el-checkbox>
              <el-checkbox class='zwx-checkbox' :label='3'>物理</el-checkbox>
              <el-checkbox class='zwx-checkbox' :label='4'>放射</el-checkbox>
              <el-checkbox class='zwx-checkbox' :label='5'>生物</el-checkbox>
              <el-checkbox class='zwx-checkbox' :label='6'>其他</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label='名称：' label-width='50px'>
            <el-input class='zwx-input' style='height: 30px;width: 160px!important;' placeholder='请输入'
                      v-model.trim='msForm.hazardsName' clearable></el-input>
          </el-form-item>
          <el-form-item label='编码：' label-width='50px'>
            <el-input class='zwx-input' style='height: 30px;width: 160px!important;' placeholder='请输入'
                      v-model.trim='msForm.codeNo' clearable></el-input>
          </el-form-item>
          <el-form-item label='CAS：' label-width='50px'>
            <el-input class='zwx-input' style='height: 30px;width: 160px!important;' placeholder='请输入'
                      v-model.trim='msForm.cas' clearable></el-input>
          </el-form-item>
          <el-form-item label='状态：' label-width='50px'>
            <el-checkbox-group class='zwx-checkbox-group' v-model='msForm.ifEnableLists'>
              <el-checkbox class='zwx-checkbox' :label='1'>启用</el-checkbox>
              <el-checkbox class='zwx-checkbox' :label='0'>停用</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-button class='zwx-button zwx-button-icontext-28' type='primary' icon='el-icon-search' @click='search(1)'>查询
          </el-button>
          <el-button class='zwx-button zwx-button-icontext-28' icon='el-icon-plus' @click='add'>添加</el-button>
        </div>
      </el-form>
      <el-table class='zwx-table sortable-table' style='width: 100%' row-key='rid' key='backlogForm'
                :data='dataList' stripe border tooltip-effect='light'>
        <el-table-column prop='codeNo' label='编码' width='100' header-align='center' align='left'>
        </el-table-column>
        <el-table-column prop='levelCodeNo' label='层级编码' width='150' header-align='center' align='center'
                         :show-overflow-tooltip='true'>
        </el-table-column>
        <el-table-column prop='hazardsSort' label='类别' width='80' header-align='center' align='center'>
          <template slot-scope='scope'>
            {{ scope.row.hazardsSort === 1 ? '粉尘' : scope.row.hazardsSort === 2 ? '化学' : scope.row.hazardsSort === 3 ? '物理' : scope.row.hazardsSort === 4 ? '放射' : scope.row.hazardsSort === 5 ? '生物' : scope.row.hazardsSort === 6 ? '其他' : ''
            }}
          </template>
        </el-table-column>
        <el-table-column prop='hazardsName' label='名称' width='250' header-align='center' align='left'>
        </el-table-column>
        <el-table-column prop='cas' label='CAS' width='180' header-align='center' align='center'>
        </el-table-column>
        <el-table-column prop='ifEnable' label='状态' width='100' header-align='center' align='center'>
          <template slot-scope='scope'>
            <div class='zwx-circle-text zwx-circle-normal' v-if='scope.row.ifEnable'>启用</div>
            <div class='zwx-circle-text zwx-circle-finish' v-else>停用</div>
          </template>
        </el-table-column>
        <el-table-column label='操作' header-align='left' align='left' fixed='right' :min-width='160'>
          <template slot-scope='scope'>
            <el-button class='zwx-button zwx-button-text-26' type='text' icon='el-icon-top-right'
                       @click='edit(scope.row)'>编辑
            </el-button>
            <el-button class='zwx-button zwx-button-text-26' :class=" 'zwx-button-danger' "
                       type='text'
                       @click="$system.msgbox(scope.row, '提示',  '确定要删除吗？', '确定', '取消', del)">
              删除
            </el-button>
            <el-button class='zwx-button zwx-button-text-26' :class="scope.row.ifEnable ? 'zwx-button-danger' : ''"
                       type='text'
                       @click="$system.msgbox(scope.row, '提示', scope.row.ifEnable ? '确定要停用吗？' : '确定要启用吗？', '确定', '取消', statusStop)">
              {{ scope.row.ifEnable ? '停用' : '启用' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <base-pagination class='normal-pagination' :parentPage='currentPage'
                     :pageSize='pageSize' :total='total'
                     @currentChange='search' />
      <base-dialog ref='msDialog' class='edit-dialog' :title='title' @opened='dialogOpened()' @determine='submit()'
                      @cancel='cancelDepartmentDialog' :pagination='false' style='width: 836px!important;'
                      :checkName1='chackName1' :type1='type1' :check-name2='chackName2'>
        <el-form ref='userForm' class='zwx-form' :model='msFormDialog' :rules='rules'>
          <div class='edit-form'>
            <div class='edit-form'>
              <el-form-item label='类别：' label-width='110px' prop='hazardsSort'>
                <el-radio-group class='zwx-radio-group' style='min-width:68px!important'
                                v-model='msFormDialog.hazardsSort'>
                  <el-radio class='zwx-radio' :label='1'>粉尘</el-radio>
                  <el-radio class='zwx-radio' :label='2'>化学</el-radio>
                  <el-radio class='zwx-radio' :label='3'>物理</el-radio>
                  <el-radio class='zwx-radio' :label='4'>放射</el-radio>
                  <el-radio class='zwx-radio' :label='5'>生物</el-radio>
                  <el-radio class='zwx-radio' :label='6'>其他</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class='edit-form'>
              <el-form-item label='名称：' label-width='110px' prop='hazardsName'>
                <el-input class='zwx-input' style='width:350px !important'
                          placeholder='' v-model='msFormDialog.hazardsName' maxlength='50' clearable />
              </el-form-item>
            </div>
            <div>
              <el-form-item label='拼音码：' label-width='110px'>
                <el-input class='zwx-input' style='width:350px !important' placeholder=''
                          v-model.trim='msFormDialog.pinyinCode' maxlength='50' clearable></el-input>
              </el-form-item>
            </div>
            <div class='edit-form'>
              <el-form-item label='CAS：' label-width='110px' prop='cas'>
                <el-input class='zwx-input' style='width:350px !important'
                          placeholder='' v-model='msFormDialog.cas' maxlength='20' clearable />
              </el-form-item>
            </div>
            <div class='edit-form'>
              <el-form-item label='编码：' label-width='110px' prop='codeNo'>
                <el-input class='zwx-input' style='width:350px !important'
                          placeholder='' v-model='msFormDialog.codeNo' maxlength='20' clearable />
              </el-form-item>
            </div>
            <div class='edit-form'>
              <el-form-item label='层级编码：' label-width='110px' prop='levelCodeNo'>
                <el-input class='zwx-input' style='width:350px !important'
                          placeholder='' v-model='msFormDialog.levelCodeNo' maxlength='20' clearable />
              </el-form-item>
            </div>
          </div>
        </el-form>
      </base-dialog> -->
  </div>
</template>

<script>
import json from './demo.js'
export default {
  name: 'HazardFactorStandard',
  data() {
    return {
      api: this.$store.state.api,
      msForm: {
        hazardsSortLists: [],
        hazardsName: '',
        codeNo: '',
        cas: '',
        ifEnableLists: [1],
        showCheckDataList: [{}],
      },
      options: [
        {
          value: '1',
          label: '粉尘',
        },
        {
          value: '2',
          label: '化学',
        },
        {
          value: '3',
          label: '物理',
        },
        {
          value: '4',
          label: '放射',
        },
        {
          value: '5',
          label: '生物',
        },
        {
          value: '6',
          label: '其他',
        },
      ],
      dataList: [],
      total: 0,
      pageSize: 18,
      currentPage: 1,
      //单位id
      employerId: '',
      /**
       * 弹框部分
       **/
      title: '添加危害因素',
      //弹框按钮名称
      chackName1: '确认',
      chackName2: '取消',
      type1: 'primary',
      msFormDialog: {
        rid: '',
        hazardsSort: '',
        hazardsName: '',
        codeNo: '',
        cas: '',
        levelCodeNo: '',
        pinyinCode: '',
      },
      rules: {
        hazardsNameDetail: [{ required: true, message: '请输入名称', trigger: ['change', 'blur'] }],
        hazardsSort: [{ required: true, message: '请选择类别', trigger: ['change', 'blur'] }],
        hazardsName: [{ required: true, message: '请输入名称', trigger: ['blur'] }],
        codeNo: [{ required: true, message: '请输入编码', trigger: ['blur'] }],
        levelCodeNo: [{ required: true, message: '请输入层级编码', trigger: ['blur'] }],
      },
      showDataList: [],
      searchInput: '',
      hazardShow: true,
      selectedNode: {},
    }
  },
  computed: {},
  watch: {},
  activated() {
    if (this.$route.params.fresh) this.search()
  },
  created() {},
  mounted() {
    let url = this.$route.query
    if (url.employerID != undefined) {
      this.employerId = url.employerID
    }
    var dataList = json.dataList
    if (this.$zwxBase.verifyIsNotBlank(dataList)) {
      dataList.forEach(item => {
        if (this.$zwxBase.verifyIsBlank(item.upcodeNo)) {
          item.disabled = true
        }
      })
    }
    this.setLinearDataList(dataList)
    // this.search(1)
  },
  methods: {
    add() {
    //   this.msForm.showCheckDataList = [...this.msForm.showCheckDataList, {}]
      this.$set(this.msForm,'showCheckDataList',[...this.msForm.showCheckDataList, {}])
      console.log(this.msForm.showCheckDataList)
    },
    factorClick(node) {
      // console.log(node)
      if (!node.children) {
        this.msForm.showCheckDataList = [{ hazardsNameDetail: '' }]
        this.selectedNode = node
        // node.dataLevelCode
      }
    },
    // 树形节点过滤
    filterNodeMethod(query, data) {
      if (this.checkIfExcludeCode(data)) return false
      if (query === undefined || query === null || query === '') return true
      return data['hazardsName'].indexOf(query) !== -1 || (data['pinyinCode'] && data['pinyinCode'].toUpperCase().indexOf(query.toUpperCase()) !== -1) || ('hazardsName' !== undefined && 'hazardsName' !== null && 'hazardsName' !== '' && data['hazardsName'].toUpperCase().indexOf(query.toUpperCase()) !== -1) || data.cas.indexOf(query) !== -1 || (this.$zwxBase.verifyIsNotBlank(data.cas) && data.cas.toUpperCase().indexOf(query.toUpperCase()) !== -1)
    },
    setLinearDataList(linearDataList) {
      if (linearDataList === undefined || linearDataList === null || linearDataList === '') return
      // 深拷贝集合，不动外部的集合
      const linearDataCopyList = JSON.parse(JSON.stringify(linearDataList))
      this.dataList.length = 0
      const dataList = []
      for (let i = 0, length = linearDataCopyList.length; i < length; i++) {
        linearDataCopyList[i].label = linearDataCopyList[i]['hazardsName']
        linearDataCopyList[i]['hazardName'] = linearDataCopyList[i]['hazardsName']
        linearDataCopyList[i].dataLevelCode = linearDataCopyList[i]['codeNo']
        if (this.$zwxBase.verifyIsNotBlank(linearDataCopyList[i]['cas'])) {
          linearDataCopyList[i]['cas'] = linearDataCopyList[i]['cas']
        } else {
          linearDataCopyList[i]['cas'] = ''
        }
        this.dataList.push(linearDataCopyList[i])
        dataList.push(linearDataCopyList[i])
      }
      // 线形转树形第一级
      this.linearDataListTreeFisrt(dataList)
      // 线形转树形递归
      this.linearDataListTreeRecursion(this.showDataList, dataList)
      // 清零临时数据
      dataList.length = 0
      // 回显
      this.echoCheckDataList()
    },
    // 线形转树形第一级
    linearDataListTreeFisrt(dataList) {
      this.showDataList.length = 0
      for (let i = 0, length = dataList.length; i < length; i++) {
        const data = dataList[i]
        if (data['upcodeNo'] !== undefined && data['upcodeNo'] !== null && data['upcodeNo'] !== '' && data['upcodeNo'] !== data['codeNo']) continue
        this.showDataList.push(dataList[i])
        dataList.splice(i, 1)
        length--
        i--
      }
    },
    // 线形转树形递归
    linearDataListTreeRecursion(showDataList, dataList) {
      for (let i = 0, length = showDataList.length; i < length; i++) {
        for (let j = 0, jLength = dataList.length; j < jLength; j++) {
          if (showDataList[i]['codeNo'] !== dataList[j]['upcodeNo']) continue
          if (showDataList[i].children === undefined) showDataList[i].children = []
          dataList[j].dataLevelCode = showDataList[i].dataLevelCode + '.' + dataList[j]['codeNo']
          showDataList[i].children.push(dataList[j])
          dataList.splice(j, 1)
          jLength--
          j--
        }
        if (showDataList[i].children !== undefined) this.linearDataListTreeRecursion(showDataList[i].children, dataList)
      }
    },
    echoCheckDataList() {
      if (this.showDataList.length === 0) return
      this.msForm.showCheckDataList.length = 0
      this.checkDataList.length = 0
      for (let i = 0, length = this.echoCheckDataCodeList.length; i < length; i++) {
        const index = this.dataList.findIndex(data => {
          return data['codeNo'] === this.echoCheckDataCodeList[i]
        })
        if (index === -1) continue
        if (this.checkIfExcludeCode(this.dataList[index])) continue
        this.checkDataList.push(this.dataList[index])
      }
      this.$refs[this.treeRef] && this.$refs[this.treeRef].setCheckedNodes(this.checkDataList)
      this.msForm.showCheckDataList = this.$refs[this.treeRef] && this.$refs[this.treeRef].getCheckedNodes(this.ifJustLeaf)
      this.input = this.checkDataList.map(checkData => checkData['hazardName']).join('、')
    },
    // 判断是否是排除项
    checkIfExcludeCode(data) {
      if (this.excludeCodeList.indexOf(data['codeNo']) !== -1) return true
      if (data.dataLevelCode.indexOf('.') !== -1) {
        const index = this.excludeCodeList.findIndex(excludeCode => {
          return data.dataLevelCode.indexOf(excludeCode + '.') === 0 || data.dataLevelCode.indexOf('.' + excludeCode + '.') !== -1 || data.dataLevelCode.indexOf('.' + excludeCode) === data.dataLevelCode.length - ('.' + excludeCode).length
        })
        if (index !== -1) return true
      }
      return false
    },
    //查询
    search(currentPage) {
      this.currentPage = currentPage
      this.$emit('loading', true)
      let data = {
        currentPage: currentPage,
        hazardsSortStr: this.msForm.hazardsSortLists.join(',') || null,
        ifEnableStr: this.msForm.ifEnableLists.join(',') || null,
        hazardsName: this.msForm.hazardsName || null,
        codeNo: this.msForm.codeNo || null,
        cas: this.msForm.cas || null,
      }
      this.$system.get(
        this.api + '/oh/repository/getHazardsFactorStandardList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.dataList = data.hazardsFactorStandardList
            this.total = data.hazardsFactorStandardCount
            this.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        this.error
      )
    },
    //添加
    // add() {
    //   this.$refs.msDialog.show(true)
    // },
    //编辑
    edit(row) {
      this.title = '编辑危害因素'
      let data = {
        rid: row.rid,
      }
      this.$system.get(
        this.api + '/oh/repository/getHazardsFactorStandard-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.msFormDialog = data.hazardsFactorStandard
            this.$refs.msDialog.show(true)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        this.error
      )
    },
    //删除
    del(row) {
      let data = {
        rid: row.rid,
      }
      this.$system.postJson(
        this.api + '/oh/repository/deleteHazardsFactorStandardByID-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.search(1)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    //状态
    statusStop(row) {
      let data = {
        rid: row.rid,
        ifEnable: row.ifEnable,
      }
      this.$system.postJson(
        this.api + '/oh/repository/enableOrDisableHazardsFactorStandardByID-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.search(1)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 弹框部分
     */
    dialogOpened() {},
    //提交
    submit() {
      this.$refs['userForm'].validate(valid => {
        if (valid) {
          this.$emit('loading', true)
          let data = {
            ...this.msFormDialog,
          }
          this.$system.postJson(
            this.api + '/oh/repository/saveOrUpdateHazardsFactorStandard-1',
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type == '00') {
                this.$system.notify('成功', data.mess, 'success')
                this.$refs.msDialog.show(false)
                this.$set(this.msFormDialog, 'hazardsSort', '')
                this.$set(this.msFormDialog, 'rid', '')
                this.$set(this.msFormDialog, 'hazardsName', '')
                this.$set(this.msFormDialog, 'codeNo', '')
                this.$set(this.msFormDialog, 'cas', '')
                this.$set(this.msFormDialog, 'levelCodeNo', '')
                this.$set(this.msFormDialog, 'pinyinCode', '')
                this.search(1)
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
            },
            data => {
              this.$emit('loading', false)
              this.$system.notify('错误', data.mess, 'error')
            }
          )
        }
      })
    },
    //取消
    cancelDepartmentDialog() {
      this.$refs.msDialog.show(false)
      this.msFormDialog.hazardsSort = ''
      this.msFormDialog.hazardsName = ''
      this.msFormDialog.codeNo = ''
      this.msFormDialog.cas = ''
      this.msFormDialog.rid = ''
      this.msFormDialog.levelCodeNo = ''
      this.msFormDialog.pinyinCode = ''
    },
  },
}
</script>

<style lang="less" scoped>
.edit-dialog /deep/ .el-dialog {
  min-width: 550px !important;
  width: 550px !important;
}

.edit-form {
  /deep/ .zwx-select > .el-input.el-input--suffix > .el-input__inner {
    width: 420px !important;
  }
}
/deep/ .el-tree {
  color: #000000;
  line-height: 38px !important;
  // 将每一行的设置为相对定位 方便后面before after 使用绝对定位来固定位置
  // overflow: auto; //注释这个  可以清除弹框的 树 的 纵向滚动条
  .el-tree-node {
    position: relative;
    padding-left: 0px;
  }
  // 子集像右偏移 给数线留出距离
  .el-tree-node__children {
    // padding-left: 8px;
    // color: #606266;
    line-height: 30px !important;
  }

  //这是竖线
  .el-tree-node :last-child:before {
    height: 40px;
  }
  .el-tree > .el-tree-node:before {
    border-left: none;
  }
  .el-tree > .el-tree-node:after {
    border-top: none;
  }
  //这自定义的线 的公共部分
  .el-tree-node:before,
  .el-tree-node:after {
    content: '';
    left: -4px;
    position: absolute;
    right: auto;
    border-width: 1px;
  }
  .tree :first-child .el-tree-node:before {
    border-left: none;
  }
  // 竖线
  .el-tree-node:before {
    border-left: none;
    bottom: 0px;
    height: 100%;
    top: -25px;
    width: 1px;
  }
  //横线
  //   .el-tree-node:after {
  //     border-top: 1px solid #b3b7cc;
  //     height: 20px;
  //     top: 14px;
  //     width: 6px;
  //   }
  .el-tree-node__expand-icon.is-leaf {
    width: 10px;
  }
  //每一行的高度
  .el-tree-node__content {
    // line-height: 30px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #d5d9e0;
    // padding-left: 5px !important;
    border-top: node;
    // border-bottom:node;
  }
  .el-tree-node__content:hover {
    background-color: rgba(245, 249, 255) !important;
    color: #2a50d7;
  }
  .el-tree-node:focus > .el-tree-node__content {
    background-color: #fff !important; //背景色
    color: blue !important;
  }

  .table-item-add-icon {
    float: right;
    justify-content: flex-end;
  }
}
//去掉最上级的before  after 即是去电最上层的连接线
/deep/ .el-tree > div {
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
}
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  // 设置颜色
  background-color: rgba(135, 206, 235, 0.2); // 透明度为0.2的skyblue，作者比较喜欢的颜色
  color: #409eff; // 节点的字体颜色
  font-weight: bold; // 字体加粗
}
</style>

<style lang="less"></style>
