var test={
    "ms": "2024-07-25 14:52:51",
    "dataList": [
        {
            "rid": "1",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "11",
            "levelCodeNo": "11",
            "hazardsName": "粉尘",
            "hazardNature": "0",
            "ifEnable": true
        },
        {
            "rid": "2",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110001",
            "levelCodeNo": "11.110001",
            "hazardsName": "矽尘（游离SiO2含量≥10%）",
            "cas": "14808-60-7",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "3",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110002",
            "levelCodeNo": "11.110002",
            "hazardsName": "煤尘（游离 SiO2 含量＜10%）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "4",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110003",
            "levelCodeNo": "11.110003",
            "hazardsName": "石墨粉尘",
            "cas": "7782-42-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "5",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110004",
            "levelCodeNo": "11.110004",
            "hazardsName": "炭黑粉尘",
            "cas": "1333-86-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "6",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110005",
            "levelCodeNo": "11.110005",
            "hazardsName": "石棉（石棉含量＞10%）粉尘纤维",
            "cas": "1332-21-4",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "7",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110006",
            "levelCodeNo": "11.110006",
            "hazardsName": "滑石粉尘（游离 SiO2 含量＜10%）",
            "cas": "14807-96-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "8",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110007",
            "levelCodeNo": "11.110007",
            "hazardsName": "水泥粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "9",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110008",
            "levelCodeNo": "11.110008",
            "hazardsName": "云母粉尘",
            "cas": "12001-26-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "10",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110009",
            "levelCodeNo": "11.110009",
            "hazardsName": "陶土粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "11",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110010",
            "levelCodeNo": "16.160014",
            "hazardsName": "铝尘",
            "cas": "8734-8375-83",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "12",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110011",
            "levelCodeNo": "11.110011",
            "hazardsName": "电焊烟尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "13",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110012",
            "levelCodeNo": "11.110012",
            "hazardsName": "铸造粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "14",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110013",
            "levelCodeNo": "11.110013",
            "hazardsName": "沉淀 SiO2（白炭黑）",
            "cas": "112926-00-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "15",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110014",
            "levelCodeNo": "11.110014",
            "hazardsName": "白云石粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "16",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110015",
            "levelCodeNo": "11.110015",
            "hazardsName": "玻璃钢粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "17",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110016",
            "levelCodeNo": "11.110016",
            "hazardsName": "玻璃棉粉尘",
            "cas": "65997-17-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "18",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110017",
            "levelCodeNo": "11.110017",
            "hazardsName": "茶尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "19",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110018",
            "levelCodeNo": "11.110018",
            "hazardsName": "大理石粉尘 （碳酸钙）",
            "cas": "1317-65-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "20",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110019",
            "levelCodeNo": "11.110019",
            "hazardsName": "二氧化钛粉尘",
            "cas": "13463-67-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "21",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110020",
            "levelCodeNo": "11.110020",
            "hazardsName": "沸石粉尘",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "22",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110021",
            "levelCodeNo": "11.110021",
            "hazardsName": "谷物粉尘（游离SiO2含量<10%）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "23",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110022",
            "levelCodeNo": "11.110022",
            "hazardsName": "硅灰石粉尘",
            "cas": "13983-17-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "24",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110023",
            "levelCodeNo": "11.110023",
            "hazardsName": "硅藻土粉尘（游离SiO2含量<10%）",
            "cas": "61790-53-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "25",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110024",
            "levelCodeNo": "11.110024",
            "hazardsName": "活性炭粉尘",
            "cas": "64365-11-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "26",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110025",
            "levelCodeNo": "11.110025",
            "hazardsName": "聚丙烯粉尘",
            "cas": "9003-07-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "27",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110026",
            "levelCodeNo": "11.110026",
            "hazardsName": "聚丙烯腈纤维粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "28",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110027",
            "levelCodeNo": "11.110027",
            "hazardsName": "聚氯乙烯粉尘",
            "cas": "9002-86-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "29",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110028",
            "levelCodeNo": "11.110028",
            "hazardsName": "聚乙烯粉尘",
            "cas": "9002-88-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "30",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110029",
            "levelCodeNo": "11.110029",
            "hazardsName": "矿渣棉粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "31",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110030",
            "levelCodeNo": "11.110030",
            "hazardsName": "麻尘（亚麻、黄麻、芒麻）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "32",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110031",
            "levelCodeNo": "11.110031",
            "hazardsName": "棉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "33",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110032",
            "levelCodeNo": "11.110032",
            "hazardsName": "木粉尘（硬）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "34",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110033",
            "levelCodeNo": "11.110033",
            "hazardsName": "膨润土粉尘",
            "cas": "1302-78-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "35",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110034",
            "levelCodeNo": "11.110034",
            "hazardsName": "皮毛粉尘",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "36",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110035",
            "levelCodeNo": "11.110035",
            "hazardsName": "桑蚕丝尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "37",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110036",
            "levelCodeNo": "11.110036",
            "hazardsName": "砂轮磨尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "38",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110037",
            "levelCodeNo": "11.110037",
            "hazardsName": "石膏粉尘 （硫酸钙）",
            "cas": "10101-41-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "39",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110038",
            "levelCodeNo": "11.110038",
            "hazardsName": "石灰石粉尘",
            "cas": "1317-65-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "40",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110039",
            "levelCodeNo": "11.110039",
            "hazardsName": "碳化硅粉尘",
            "cas": "409-21-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "41",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110040",
            "levelCodeNo": "11.110040",
            "hazardsName": "碳纤维粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "42",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110041",
            "levelCodeNo": "11.110041",
            "hazardsName": "稀土粉尘（游离SiO2含量<10%）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "43",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110042",
            "levelCodeNo": "11.110042",
            "hazardsName": "烟草尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "44",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110043",
            "levelCodeNo": "11.110043",
            "hazardsName": "岩棉粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "45",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110044",
            "levelCodeNo": "11.110044",
            "hazardsName": "萤石混合性粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "46",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110045",
            "levelCodeNo": "11.110045",
            "hazardsName": "珍珠岩粉尘",
            "cas": "93763-70-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "47",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110046",
            "levelCodeNo": "11.110046",
            "hazardsName": "蛭石粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "48",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110047",
            "levelCodeNo": "11.110047",
            "hazardsName": "硫酸钡（按 Ba 计）",
            "cas": "7727-43-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "49",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110048",
            "levelCodeNo": "11.110048",
            "hazardsName": "锡及其化合物粉尘",
            "cas": "7440-31-5（锡）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "50",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110049",
            "levelCodeNo": "11.110049",
            "hazardsName": "铁及其化合物粉尘",
            "cas": "7439-89-6（铁）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "51",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110050",
            "levelCodeNo": "11.110050",
            "hazardsName": "锑及其化合物粉尘",
            "cas": "7440-36-0（锑）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "52",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110051",
            "levelCodeNo": "11.110051",
            "hazardsName": "硬质合金粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "53",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110052",
            "levelCodeNo": "11.110052",
            "hazardsName": "有机粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "54",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110053",
            "levelCodeNo": "11.110053",
            "hazardsName": "致金属及其化合物粉尘肺沉着病的粉尘（锡、铁、锑、钡及其化合物等）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "55",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110054",
            "levelCodeNo": "11.110054",
            "hazardsName": "硬金属粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "56",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110055",
            "levelCodeNo": "11.110055",
            "hazardsName": "玻璃纤维",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "57",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110100",
            "levelCodeNo": "11.110100",
            "hazardsName": "其他粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "58",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "12",
            "levelCodeNo": "12",
            "hazardsName": "化学因素",
            "hazardNature": "0",
            "ifEnable": true
        },
        {
            "rid": "59",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120001",
            "levelCodeNo": "12.120001",
            "hazardsName": "铅及其无机化合物（按 Pb 计，不包括四乙基铅）",
            "cas": "7439-92-1（铅）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "60",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120002",
            "levelCodeNo": "12.120002",
            "hazardsName": "汞-有机汞化合物（按 Hg 计）",
            "cas": "7439-97-6（汞）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "61",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120003",
            "levelCodeNo": "12.120003",
            "hazardsName": "锰及其无机化合物（按 MnO2 计）",
            "cas": "7439-96-5（锰）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "62",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120004",
            "levelCodeNo": "12.120004",
            "hazardsName": "镉及其化合物（按 Cd 计）",
            "cas": "7440-43-9（镉）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "63",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120005",
            "levelCodeNo": "12.120005",
            "hazardsName": "铍及其化合物（按 Be 计）",
            "cas": "7440-41-7（铍）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "64",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120006",
            "levelCodeNo": "12.120006",
            "hazardsName": "铊及其可溶性化合物（按 Tl 计）",
            "cas": "7440-28-0（铊）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "65",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120007",
            "levelCodeNo": "12.120007",
            "hazardsName": "钡及其可溶性化合物（按 Ba 计）",
            "cas": "7440-39-3（钡）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "66",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120008",
            "levelCodeNo": "12.120008",
            "hazardsName": "钒及其化合物（按 V 计）",
            "cas": "7440-62-6（钒）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "67",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120009",
            "levelCodeNo": "12.120009",
            "hazardsName": "磷及其化合物（磷化氢、磷化锌、磷化铝、有机磷单列）",
            "cas": "7723-14-0（磷）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "68",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120010",
            "levelCodeNo": "12.120010",
            "hazardsName": "砷及其化合物（砷化氢单列）",
            "cas": "7440-38-2（砷）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "69",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120011",
            "levelCodeNo": "12.120011",
            "hazardsName": "铀及其化合物",
            "cas": "7440-61-1（铀）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "70",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120012",
            "levelCodeNo": "12.120012",
            "hazardsName": "砷化氢（胂）",
            "cas": "7784-42-1",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "71",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120013",
            "levelCodeNo": "12.120013",
            "hazardsName": "氯，氯气",
            "cas": "7782-50-5",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "72",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120014",
            "levelCodeNo": "12.120014",
            "hazardsName": "二氧化硫",
            "cas": "7446-9-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "73",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120015",
            "levelCodeNo": "12.120015",
            "hazardsName": "光气（碳酰氯）",
            "cas": "75-44-5",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "74",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120016",
            "levelCodeNo": "12.120016",
            "hazardsName": "氨",
            "cas": "7664-41-7",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "75",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120017",
            "levelCodeNo": "12.120017",
            "hazardsName": "偏二甲基肼",
            "cas": "57-14-7",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "76",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120018",
            "levelCodeNo": "12.120018",
            "hazardsName": "氮氧化合物",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "77",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120019",
            "levelCodeNo": "12.120019",
            "hazardsName": "一氧化碳",
            "cas": "630-08-0",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "78",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120020",
            "levelCodeNo": "12.120020",
            "hazardsName": "二硫化碳",
            "cas": "75-15-0",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "79",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120021",
            "levelCodeNo": "12.120021",
            "hazardsName": "硫化氢",
            "cas": "7783-6-4",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "80",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120022",
            "levelCodeNo": "12.120022",
            "hazardsName": "磷化锌",
            "cas": "1314-84-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "81",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120023",
            "levelCodeNo": "12.120023",
            "hazardsName": "氟及其化合物（不含氟化氢）（按 F 计）",
            "cas": "7782-41-4（氟）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "82",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120024",
            "levelCodeNo": "12.120024",
            "hazardsName": "氰及其腈类化合物",
            "cas": "460-19-5（氰）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "83",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120025",
            "levelCodeNo": "12.120025",
            "hazardsName": "四乙基铅（按 Pb 计）",
            "cas": "78-00-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "84",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120026",
            "levelCodeNo": "12.120026",
            "hazardsName": "有机锡",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "85",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120027",
            "levelCodeNo": "12.120027",
            "hazardsName": "羰基镍（按 Ni 计）",
            "cas": "13463-39-3",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "86",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120028",
            "levelCodeNo": "12.120028",
            "hazardsName": "苯",
            "cas": "71-43-2",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "87",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120029",
            "levelCodeNo": "12.120029",
            "hazardsName": "甲苯",
            "cas": "108-88-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "88",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120030",
            "levelCodeNo": "12.120030",
            "hazardsName": "二甲苯（全部异构体）",
            "cas": "1330-20-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "89",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120031",
            "levelCodeNo": "12.120031",
            "hazardsName": "正己烷",
            "cas": "110-54-3",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "90",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120032",
            "levelCodeNo": "12.120032",
            "hazardsName": "汽油",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "91",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120033",
            "levelCodeNo": "12.120033",
            "hazardsName": "一甲胺",
            "cas": "74-89-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "92",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120034",
            "levelCodeNo": "12.120034",
            "hazardsName": "有机氟聚合物单体及其热裂解物",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "93",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120035",
            "levelCodeNo": "12.120035",
            "hazardsName": "二氯乙烷",
            "cas": "1300-21-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "94",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120036",
            "levelCodeNo": "12.120036",
            "hazardsName": "四氯化碳",
            "cas": "56-23-5",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "95",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120038",
            "levelCodeNo": "12.120038",
            "hazardsName": "三氯乙烯",
            "cas": "79-01-6",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "96",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120039",
            "levelCodeNo": "12.120039",
            "hazardsName": "氯丙烯",
            "cas": "107-05-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "97",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120040",
            "levelCodeNo": "12.120040",
            "hazardsName": "β-氯丁二烯（氯丁二烯）",
            "cas": "126-99-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "98",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120041",
            "levelCodeNo": "12.120041",
            "hazardsName": "苯的氨基及硝基化合物（不含三硝基甲苯）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "99",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120042",
            "levelCodeNo": "12.120042",
            "hazardsName": "三硝基甲苯",
            "cas": "118-96-7",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "100",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120043",
            "levelCodeNo": "12.120043",
            "hazardsName": "甲醇",
            "cas": "67-56-1",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "101",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120044",
            "levelCodeNo": "12.120044",
            "hazardsName": "酚",
            "cas": "108-95-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "102",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120045",
            "levelCodeNo": "12.120045",
            "hazardsName": "五氯酚及其钠盐",
            "cas": "87-86-5（五氯酚）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "103",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120046",
            "levelCodeNo": "12.120046",
            "hazardsName": "甲醛",
            "cas": "50-00-0",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "104",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120047",
            "levelCodeNo": "12.120047",
            "hazardsName": "硫酸二甲酯",
            "cas": "77-78-1",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "105",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120048",
            "levelCodeNo": "12.120048",
            "hazardsName": "丙烯酰胺",
            "cas": "1979-6-1",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "106",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120049",
            "levelCodeNo": "12.120049",
            "hazardsName": "二甲基甲酰胺",
            "cas": "68-12-2",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "107",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120050",
            "levelCodeNo": "12.120050",
            "hazardsName": "有机磷",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "108",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120051",
            "levelCodeNo": "12.120051",
            "hazardsName": "氨基甲酸酯类",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "109",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120052",
            "levelCodeNo": "12.120052",
            "hazardsName": "杀虫脒",
            "cas": "19750-95-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "110",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120053",
            "levelCodeNo": "12.120053",
            "hazardsName": "溴甲烷",
            "cas": "74-83-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "111",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120054",
            "levelCodeNo": "12.120054",
            "hazardsName": "拟除虫菊酯",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "112",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120055",
            "levelCodeNo": "12.120055",
            "hazardsName": "铟及其化合物（按 In 计）",
            "cas": "7440-74-6（铟）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "113",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120056",
            "levelCodeNo": "12.120056",
            "hazardsName": "溴丙烷（1-溴丙烷；2-溴丙烷）",
            "cas": "106-94-5；75-26-3",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "114",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120057",
            "levelCodeNo": "12.120057",
            "hazardsName": "碘甲烷",
            "cas": "74-88-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "115",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120058",
            "levelCodeNo": "12.120058",
            "hazardsName": "氯乙酸",
            "cas": "1979-11-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "116",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120059",
            "levelCodeNo": "12.120059",
            "hazardsName": "环氧乙烷",
            "cas": "75-21-8",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "117",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120060",
            "levelCodeNo": "12.120060",
            "hazardsName": "氨基磺酸铵",
            "cas": "7773-06-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "118",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120061",
            "levelCodeNo": "12.120061",
            "hazardsName": "氯化铵烟",
            "cas": "12125-02-9（氯化铵）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "119",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120062",
            "levelCodeNo": "12.120062",
            "hazardsName": "氯磺酸",
            "cas": "7790-94-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "120",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120063",
            "levelCodeNo": "12.120063",
            "hazardsName": "氢氧化铵",
            "cas": "1336-21-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "121",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120064",
            "levelCodeNo": "12.120064",
            "hazardsName": "碳酸铵",
            "cas": "506-87-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "122",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120065",
            "levelCodeNo": "12.120065",
            "hazardsName": "α-氯乙酰苯",
            "cas": "532-27-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "123",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120066",
            "levelCodeNo": "12.120066",
            "hazardsName": "对特丁基甲苯",
            "cas": "98-51-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "124",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120067",
            "levelCodeNo": "12.120067",
            "hazardsName": "二乙烯基苯",
            "cas": "1321-74-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "125",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120068",
            "levelCodeNo": "12.120068",
            "hazardsName": "过氧化苯甲酰",
            "cas": "94-36-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "126",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120069",
            "levelCodeNo": "12.120069",
            "hazardsName": "乙苯",
            "cas": "100-41-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "127",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120070",
            "levelCodeNo": "12.120070",
            "hazardsName": "碲化铋（按 Bi2Te3 计）",
            "cas": "1304-82-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "128",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120071",
            "levelCodeNo": "12.120071",
            "hazardsName": "铂化物",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "129",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120072",
            "levelCodeNo": "12.120072",
            "hazardsName": "1,3-丁二烯",
            "cas": "106-99-0",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "130",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120073",
            "levelCodeNo": "12.120073",
            "hazardsName": "苯乙烯",
            "cas": "100-42-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "131",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120074",
            "levelCodeNo": "12.120074",
            "hazardsName": "丁烯",
            "cas": "25167-67-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "132",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120075",
            "levelCodeNo": "12.120075",
            "hazardsName": "二聚环戊二烯",
            "cas": "77-73-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "133",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120076",
            "levelCodeNo": "12.120076",
            "hazardsName": "邻氯苯乙烯",
            "cas": "2039-87-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "134",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120077",
            "levelCodeNo": "12.120077",
            "hazardsName": "乙炔",
            "cas": "74-86-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "135",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120078",
            "levelCodeNo": "12.120078",
            "hazardsName": "百草枯（1,1-二甲基-4,4-联吡啶鎓盐二氯化物）",
            "cas": "1910-42-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "136",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120079",
            "levelCodeNo": "12.120079",
            "hazardsName": "2-N-二丁氨基乙醇",
            "cas": "102-81-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "137",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120080",
            "levelCodeNo": "12.120080",
            "hazardsName": "2-二乙氨基乙醇",
            "cas": "100-37-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "138",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120081",
            "levelCodeNo": "12.120081",
            "hazardsName": "乙醇胺（氨基乙醇）",
            "cas": "141-43-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "139",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120082",
            "levelCodeNo": "12.120082",
            "hazardsName": "异丙醇胺（1-氨基-2-二丙醇）",
            "cas": "78-96-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "140",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120083",
            "levelCodeNo": "12.120083",
            "hazardsName": "1,3-二氯-2-丙醇",
            "cas": "96-23-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "141",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120084",
            "levelCodeNo": "12.120084",
            "hazardsName": "苯乙醇",
            "cas": "60-12-18",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "142",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120085",
            "levelCodeNo": "12.120085",
            "hazardsName": "正丙醇",
            "cas": "71-23-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "143",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120086",
            "levelCodeNo": "12.120086",
            "hazardsName": "丙烯醇",
            "cas": "107-18-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "144",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120087",
            "levelCodeNo": "12.120087",
            "hazardsName": "正丁醇",
            "cas": "71-36-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "145",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120088",
            "levelCodeNo": "12.120088",
            "hazardsName": "环己醇",
            "cas": "108-93-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "146",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120089",
            "levelCodeNo": "12.120089",
            "hazardsName": "己二醇",
            "cas": "107-41-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "147",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120090",
            "levelCodeNo": "12.120090",
            "hazardsName": "糠醇",
            "cas": "98-00-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "148",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120091",
            "levelCodeNo": "12.120091",
            "hazardsName": "氯乙醇",
            "cas": "107-07-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "149",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120092",
            "levelCodeNo": "12.120092",
            "hazardsName": "乙二醇",
            "cas": "107-21-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "150",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120093",
            "levelCodeNo": "12.120093",
            "hazardsName": "异丙醇",
            "cas": "67-63-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "151",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120094",
            "levelCodeNo": "12.120094",
            "hazardsName": "戊醇",
            "cas": "71-41-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "152",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120095",
            "levelCodeNo": "12.120095",
            "hazardsName": "重氮甲烷",
            "cas": "334-88-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "153",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120096",
            "levelCodeNo": "12.120096",
            "hazardsName": "多氯萘",
            "cas": "70776-03-3",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "154",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120097",
            "levelCodeNo": "12.120097",
            "hazardsName": "蒽",
            "cas": "120-12-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "155",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120098",
            "levelCodeNo": "12.120098",
            "hazardsName": "六氯萘",
            "cas": "1335-87-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "156",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120099",
            "levelCodeNo": "12.120099",
            "hazardsName": "氯萘",
            "cas": "90-13-1",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "157",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120100",
            "levelCodeNo": "12.120100",
            "hazardsName": "萘",
            "cas": "91-20-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "158",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120101",
            "levelCodeNo": "12.120101",
            "hazardsName": "萘烷",
            "cas": "91-17-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "159",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120102",
            "levelCodeNo": "12.120102",
            "hazardsName": "硝基萘",
            "cas": "86-57-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "160",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120103",
            "levelCodeNo": "12.120103",
            "hazardsName": "蒽醌及其染料",
            "cas": "84-65-1（蒽醌）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "161",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120104",
            "levelCodeNo": "12.120104",
            "hazardsName": "二苯胍",
            "cas": "102-06-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "162",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120105",
            "levelCodeNo": "12.120105",
            "hazardsName": "对苯二胺",
            "cas": "106-50-3",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "163",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120106",
            "levelCodeNo": "12.120106",
            "hazardsName": "对溴苯胺",
            "cas": "106-40-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "164",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120107",
            "levelCodeNo": "12.120107",
            "hazardsName": "卤化水杨酰苯胺（Ν-水杨酰苯胺）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "165",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120108",
            "levelCodeNo": "12.120108",
            "hazardsName": "硝基萘胺",
            "cas": "776-34-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "166",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120109",
            "levelCodeNo": "12.120109",
            "hazardsName": "对苯二甲酸二甲酯",
            "cas": "120-61-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "167",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120110",
            "levelCodeNo": "12.120110",
            "hazardsName": "邻苯二甲酸二丁酯",
            "cas": "84-74-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "168",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120111",
            "levelCodeNo": "12.120111",
            "hazardsName": "邻苯二甲酸二甲酯",
            "cas": "131-11-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "169",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120112",
            "levelCodeNo": "12.120112",
            "hazardsName": "磷酸二丁基苯酯",
            "cas": "2528-36-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "170",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120113",
            "levelCodeNo": "12.120113",
            "hazardsName": "磷酸三邻甲苯酯",
            "cas": "78-30-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "171",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120114",
            "levelCodeNo": "12.120114",
            "hazardsName": "三甲苯磷酸酯（全部异构体）",
            "cas": "1330-78-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "172",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120115",
            "levelCodeNo": "12.120115",
            "hazardsName": "1,2,3-苯三酚（焦棓酚）",
            "cas": "87-66-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "173",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120116",
            "levelCodeNo": "12.120116",
            "hazardsName": "4,6-二硝基邻苯甲酚",
            "cas": "534-52-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "174",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120117",
            "levelCodeNo": "12.120117",
            "hazardsName": "N,N-二甲基-3-氨基苯酚",
            "cas": "99-07-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "175",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120118",
            "levelCodeNo": "12.120118",
            "hazardsName": "对氨基酚",
            "cas": "123-30-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "176",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120119",
            "levelCodeNo": "12.120119",
            "hazardsName": "多氯酚",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "177",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120120",
            "levelCodeNo": "12.120120",
            "hazardsName": "二甲苯酚",
            "cas": "108-68-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "178",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120121",
            "levelCodeNo": "12.120121",
            "hazardsName": "二氯酚",
            "cas": "120-83-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "179",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120122",
            "levelCodeNo": "12.120122",
            "hazardsName": "二硝基苯酚",
            "cas": "51-28-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "180",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120123",
            "levelCodeNo": "12.120123",
            "hazardsName": "甲酚（全部异构体）",
            "cas": "1319-77-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "181",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120124",
            "levelCodeNo": "12.120124",
            "hazardsName": "甲基氨基酚",
            "cas": "55-55-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "182",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120125",
            "levelCodeNo": "12.120125",
            "hazardsName": "间苯二酚",
            "cas": "108-46-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "183",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120126",
            "levelCodeNo": "12.120126",
            "hazardsName": "邻仲丁基苯酚",
            "cas": "89-72-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "184",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120127",
            "levelCodeNo": "12.120127",
            "hazardsName": "萘酚",
            "cas": "1321-67-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "185",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120128",
            "levelCodeNo": "12.120128",
            "hazardsName": "氢醌（对苯二酚）",
            "cas": "123-31-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "186",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120129",
            "levelCodeNo": "12.120129",
            "hazardsName": "苦味酸（2,4,6-三硝基苯酚）",
            "cas": "88-89-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "187",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120130",
            "levelCodeNo": "12.120130",
            "hazardsName": "氰氨化钙",
            "cas": "156-62-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "188",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120519",
            "levelCodeNo": "12.120519",
            "hazardsName": "碳酸钙",
            "cas": "471-34-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "189",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120132",
            "levelCodeNo": "12.120132",
            "hazardsName": "氧化钙",
            "cas": "1305-78-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "190",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120133",
            "levelCodeNo": "12.120133",
            "hazardsName": "锆及其化合物（按 Zr 计）",
            "cas": "7440-67-7（锆）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "191",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120134",
            "levelCodeNo": "12.120134",
            "hazardsName": "铬及其化合物",
            "cas": "7440-47-3（铬）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "192",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120135",
            "levelCodeNo": "12.120135",
            "hazardsName": "钴及其化合物（按 Co 计）",
            "cas": "7440-48-4",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "193",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120136",
            "levelCodeNo": "12.120136",
            "hazardsName": "二甲基二氯硅烷",
            "cas": "75-78-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "194",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120137",
            "levelCodeNo": "12.120137",
            "hazardsName": "三氯氢硅",
            "cas": "10025-78-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "195",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120138",
            "levelCodeNo": "12.120138",
            "hazardsName": "四氯化硅",
            "cas": "10026-04-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "196",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120139",
            "levelCodeNo": "12.120139",
            "hazardsName": "环氧丙烷",
            "cas": "75-56-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "197",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120140",
            "levelCodeNo": "12.120140",
            "hazardsName": "环氧氯丙烷",
            "cas": "106-89-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "198",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120141",
            "levelCodeNo": "12.120141",
            "hazardsName": "柴油",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "199",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120142",
            "levelCodeNo": "12.120142",
            "hazardsName": "焦炉逸散物（按苯溶物计）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "200",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120143",
            "levelCodeNo": "12.120143",
            "hazardsName": "煤焦油",
            "cas": "8007-45-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "201",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120144",
            "levelCodeNo": "12.120144",
            "hazardsName": "煤焦油沥青挥发物（按苯溶物计）",
            "cas": "65996-93-2",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "202",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120145",
            "levelCodeNo": "12.120145",
            "hazardsName": "木馏油（焦油）",
            "cas": "8001-58-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "203",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120146",
            "levelCodeNo": "12.120146",
            "hazardsName": "石蜡烟",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "204",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120147",
            "levelCodeNo": "12.120147",
            "hazardsName": "石油沥青烟(按苯溶物计)",
            "cas": "8052-42-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "205",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120148",
            "levelCodeNo": "12.120148",
            "hazardsName": "苯肼",
            "cas": "100-63-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "206",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120149",
            "levelCodeNo": "12.120149",
            "hazardsName": "甲基肼",
            "cas": "60-34-4",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "207",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120150",
            "levelCodeNo": "12.120150",
            "hazardsName": "肼",
            "cas": "302-01-2",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "208",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120151",
            "levelCodeNo": "12.120151",
            "hazardsName": "聚氯乙烯热解物",
            "cas": "7647-01-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "209",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120152",
            "levelCodeNo": "12.120152",
            "hazardsName": "锂及其化合物",
            "cas": "7439-93-2（锂）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "210",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120153",
            "levelCodeNo": "12.120153",
            "hazardsName": "联苯胺（4,4'-二氨基联苯）",
            "cas": "92-87-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "211",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120154",
            "levelCodeNo": "12.120154",
            "hazardsName": "3,3-二甲基联苯胺",
            "cas": "119-93-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "212",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120155",
            "levelCodeNo": "12.120155",
            "hazardsName": "多氯联苯",
            "cas": "1336-36-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "213",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120156",
            "levelCodeNo": "12.120156",
            "hazardsName": "多溴联苯",
            "cas": "59536-65-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "214",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120157",
            "levelCodeNo": "12.120157",
            "hazardsName": "联苯",
            "cas": "92-52-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "215",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120158",
            "levelCodeNo": "12.120158",
            "hazardsName": "氯联苯（54 %氯）",
            "cas": "11097-69-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "216",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120159",
            "levelCodeNo": "12.120159",
            "hazardsName": "甲硫醇",
            "cas": "74-93-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "217",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120160",
            "levelCodeNo": "12.120160",
            "hazardsName": "乙硫醇",
            "cas": "75-08-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "218",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120161",
            "levelCodeNo": "12.120161",
            "hazardsName": "正丁基硫醇",
            "cas": "109-79-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "219",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120162",
            "levelCodeNo": "12.120162",
            "hazardsName": "二甲基亚砜",
            "cas": "67-68-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "220",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120163",
            "levelCodeNo": "12.120163",
            "hazardsName": "二氯化砜（磺酰氯）",
            "cas": "7791-25-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "221",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120164",
            "levelCodeNo": "12.120164",
            "hazardsName": "过硫酸盐（过硫酸钾、过硫酸钠、过硫酸铵等）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "222",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120165",
            "levelCodeNo": "12.120165",
            "hazardsName": "硫酸及三氧化硫",
            "cas": "7664-93-9",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "223",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120166",
            "levelCodeNo": "12.120166",
            "hazardsName": "六氟化硫",
            "cas": "2551-62-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "224",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120167",
            "levelCodeNo": "12.120167",
            "hazardsName": "亚硫酸钠",
            "cas": "7757-83-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "225",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120168",
            "levelCodeNo": "12.120168",
            "hazardsName": "2-溴乙氧基苯",
            "cas": "589-10-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "226",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120169",
            "levelCodeNo": "12.120169",
            "hazardsName": "苄基氯",
            "cas": "100-44-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "227",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120170",
            "levelCodeNo": "12.120170",
            "hazardsName": "苄基溴（溴甲苯）",
            "cas": "100-39-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "228",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120171",
            "levelCodeNo": "12.120171",
            "hazardsName": "多氯苯",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "229",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120172",
            "levelCodeNo": "12.120172",
            "hazardsName": "二氯苯",
            "cas": "106-46-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "230",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120173",
            "levelCodeNo": "12.120173",
            "hazardsName": "氯苯",
            "cas": "108-90-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "231",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120174",
            "levelCodeNo": "12.120174",
            "hazardsName": "溴苯",
            "cas": "108-86-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "232",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120175",
            "levelCodeNo": "12.120175",
            "hazardsName": "1,1-二氯乙烯",
            "cas": "75-35-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "233",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120176",
            "levelCodeNo": "12.120176",
            "hazardsName": "1,2-二氯乙烯（全部异构体）",
            "cas": "540-59-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "234",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120177",
            "levelCodeNo": "12.120177",
            "hazardsName": "1,3-二氯丙烯",
            "cas": "542-75-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "235",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120178",
            "levelCodeNo": "12.120178",
            "hazardsName": "二氯乙炔",
            "cas": "7572-29-4",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "236",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120179",
            "levelCodeNo": "12.120179",
            "hazardsName": "六氯丁二烯",
            "cas": "87-68-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "237",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120180",
            "levelCodeNo": "12.120180",
            "hazardsName": "六氯环戊二烯",
            "cas": "77-47-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "238",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120181",
            "levelCodeNo": "12.120181",
            "hazardsName": "四氯乙烯",
            "cas": "127-18-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "239",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120182",
            "levelCodeNo": "12.120182",
            "hazardsName": "1,1,1-三氯乙烷",
            "cas": "71-55-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "240",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120183",
            "levelCodeNo": "12.120183",
            "hazardsName": "1,2,3-三氯丙烷",
            "cas": "96-18-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "241",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120184",
            "levelCodeNo": "12.120184",
            "hazardsName": "1,2-二氯丙烷",
            "cas": "78-87-5",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "242",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120185",
            "levelCodeNo": "12.120185",
            "hazardsName": "1,3-二氯丙烷",
            "cas": "142-28-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "243",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120186",
            "levelCodeNo": "12.120186",
            "hazardsName": "二氯二氟甲烷",
            "cas": "75-71-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "244",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120187",
            "levelCodeNo": "12.120187",
            "hazardsName": "二氯甲烷",
            "cas": "75-09-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "245",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120188",
            "levelCodeNo": "12.120188",
            "hazardsName": "二溴氯丙烷",
            "cas": "96-12-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "246",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120189",
            "levelCodeNo": "12.120189",
            "hazardsName": "六氯乙烷",
            "cas": "67-72-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "247",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120190",
            "levelCodeNo": "12.120190",
            "hazardsName": "三氯甲烷（氯仿）",
            "cas": "67-66-3",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "248",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120191",
            "levelCodeNo": "12.120191",
            "hazardsName": "氯甲烷",
            "cas": "74-87-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "249",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120192",
            "levelCodeNo": "12.120192",
            "hazardsName": "氯乙烷",
            "cas": "75-00-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "250",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120193",
            "levelCodeNo": "12.120193",
            "hazardsName": "氯乙酰氯",
            "cas": "79-40-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "251",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120194",
            "levelCodeNo": "12.120194",
            "hazardsName": "三氯一氟甲烷",
            "cas": "75-69-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "252",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120195",
            "levelCodeNo": "12.120195",
            "hazardsName": "四氯乙烷",
            "cas": "79-34-5",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "253",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120196",
            "levelCodeNo": "12.120196",
            "hazardsName": "四溴化碳",
            "cas": "558-13-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "254",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120197",
            "levelCodeNo": "12.120197",
            "hazardsName": "五氟氯乙烷",
            "cas": "76-15-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "255",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120198",
            "levelCodeNo": "12.120198",
            "hazardsName": "溴乙烷",
            "cas": "74-96-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "256",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120199",
            "levelCodeNo": "12.120199",
            "hazardsName": "铝酸钠",
            "cas": "1302-42-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "257",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120200",
            "levelCodeNo": "12.120200",
            "hazardsName": "二氧化氯",
            "cas": "10049-04-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "258",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120201",
            "levelCodeNo": "12.120201",
            "hazardsName": "氯化氢及盐酸",
            "cas": "7647-01-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "259",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120202",
            "levelCodeNo": "12.120202",
            "hazardsName": "氯酸钾",
            "cas": "3811-04-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "260",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120203",
            "levelCodeNo": "12.120203",
            "hazardsName": "氯酸钠",
            "cas": "7775-09-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "261",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120204",
            "levelCodeNo": "12.120204",
            "hazardsName": "三氟化氯",
            "cas": "7790-91-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "262",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120205",
            "levelCodeNo": "12.120205",
            "hazardsName": "氯甲醚",
            "cas": "107-30-2",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "263",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120206",
            "levelCodeNo": "12.120206",
            "hazardsName": "苯基醚（二苯醚）",
            "cas": "101-84-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "264",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120207",
            "levelCodeNo": "12.120207",
            "hazardsName": "二丙二醇甲醚",
            "cas": "34590-94-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "265",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120208",
            "levelCodeNo": "12.120208",
            "hazardsName": "二氯乙醚",
            "cas": "111-44-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "266",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120209",
            "levelCodeNo": "12.120209",
            "hazardsName": "二缩水甘油醚",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "267",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120210",
            "levelCodeNo": "12.120210",
            "hazardsName": "邻-茴香胺，对-茴香胺",
            "cas": "90-04-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "268",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120211",
            "levelCodeNo": "12.120211",
            "hazardsName": "双氯甲醚",
            "cas": "542-88-1",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "269",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120212",
            "levelCodeNo": "12.120212",
            "hazardsName": "乙醚",
            "cas": "60-29-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "270",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120213",
            "levelCodeNo": "12.120213",
            "hazardsName": "正丁基缩水甘油醚",
            "cas": "2426-08-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "271",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120214",
            "levelCodeNo": "12.120214",
            "hazardsName": "钼酸",
            "cas": "13462-95-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "272",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120215",
            "levelCodeNo": "12.120215",
            "hazardsName": "钼酸铵",
            "cas": "13106-76-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "273",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120216",
            "levelCodeNo": "12.120216",
            "hazardsName": "钼酸钠",
            "cas": "7631-95-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "274",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120217",
            "levelCodeNo": "12.120217",
            "hazardsName": "三氧化钼",
            "cas": "1313-27-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "275",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120218",
            "levelCodeNo": "12.120218",
            "hazardsName": "氢氧化钠",
            "cas": "1310-73-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "276",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120219",
            "levelCodeNo": "12.120219",
            "hazardsName": "碳酸钠（纯碱）",
            "cas": "3313-92-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "277",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120220",
            "levelCodeNo": "12.120220",
            "hazardsName": "镍及其无机化合物(按 Ni 计)",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "278",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120221",
            "levelCodeNo": "12.120221",
            "hazardsName": "癸硼烷",
            "cas": "17702-41-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "279",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120222",
            "levelCodeNo": "12.120222",
            "hazardsName": "硼烷",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "280",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120223",
            "levelCodeNo": "12.120223",
            "hazardsName": "三氟化硼",
            "cas": "7637-07-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "281",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120224",
            "levelCodeNo": "12.120224",
            "hazardsName": "三氯化硼",
            "cas": "10294-34-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "282",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120225",
            "levelCodeNo": "12.120225",
            "hazardsName": "乙硼烷",
            "cas": "19287-45-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "283",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120226",
            "levelCodeNo": "12.120226",
            "hazardsName": "2-氯苯基羟胺",
            "cas": "10468-16-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "284",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120227",
            "levelCodeNo": "12.120227",
            "hazardsName": "3-氯苯基羟胺",
            "cas": "10468-17-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "285",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120228",
            "levelCodeNo": "12.120228",
            "hazardsName": "4-氯苯基羟胺",
            "cas": "823-86-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "286",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120229",
            "levelCodeNo": "12.120229",
            "hazardsName": "苯基羟胺（苯胲）",
            "cas": "100-65-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "287",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120230",
            "levelCodeNo": "12.120230",
            "hazardsName": "巴豆醛（丁烯醛）",
            "cas": "4170-30-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "288",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120231",
            "levelCodeNo": "12.120231",
            "hazardsName": "丙酮醛（甲基乙二醛）",
            "cas": "78-98-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "289",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120232",
            "levelCodeNo": "12.120232",
            "hazardsName": "丙烯醛",
            "cas": "107-02-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "290",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120233",
            "levelCodeNo": "12.120233",
            "hazardsName": "正丁醛",
            "cas": "123-72-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "291",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120234",
            "levelCodeNo": "12.120234",
            "hazardsName": "糠醛",
            "cas": "98-01-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "292",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120235",
            "levelCodeNo": "12.120235",
            "hazardsName": "氯乙醛",
            "cas": "107-20-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "293",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120236",
            "levelCodeNo": "12.120236",
            "hazardsName": "羟基香茅醛",
            "cas": "107-75-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "294",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120237",
            "levelCodeNo": "12.120237",
            "hazardsName": "三氯乙醛",
            "cas": "75-87-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "295",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120238",
            "levelCodeNo": "12.120238",
            "hazardsName": "乙醛",
            "cas": "75-07-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "296",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120239",
            "levelCodeNo": "12.120239",
            "hazardsName": "氢氧化铯",
            "cas": "21351-79-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "297",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120240",
            "levelCodeNo": "12.120240",
            "hazardsName": "氯化苄烷胺（洁尔灭）",
            "cas": "8001-54-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "298",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120241",
            "levelCodeNo": "12.120241",
            "hazardsName": "双-(二甲基硫代氨基甲酰基)二硫化物（秋兰姆、福美双）",
            "cas": "137-26-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "299",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120242",
            "levelCodeNo": "12.120242",
            "hazardsName": "安妥（α-萘硫脲）",
            "cas": "86-88-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "300",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120243",
            "levelCodeNo": "12.120243",
            "hazardsName": "杀鼠灵（3-（1-丙酮基苄基）-4-羟基香豆素；华法林）",
            "cas": "81-81-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "301",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120244",
            "levelCodeNo": "12.120244",
            "hazardsName": "酚醛树脂",
            "cas": "9003-35-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "302",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120245",
            "levelCodeNo": "12.120245",
            "hazardsName": "环氧树脂",
            "cas": "38891-59-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "303",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120246",
            "levelCodeNo": "12.120246",
            "hazardsName": "脲醛树脂",
            "cas": "25104-55-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "304",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120247",
            "levelCodeNo": "12.120247",
            "hazardsName": "三聚氰胺甲醛树脂",
            "cas": "9003-08-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "305",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120248",
            "levelCodeNo": "12.120248",
            "hazardsName": "1,2,4-苯三酸酐（TMA）",
            "cas": "552-30-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "306",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120249",
            "levelCodeNo": "12.120249",
            "hazardsName": "邻苯二甲酸酐（PA）",
            "cas": "85-44-9",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "307",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120250",
            "levelCodeNo": "12.120250",
            "hazardsName": "马来酸酐",
            "cas": "108-31-6",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "308",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120251",
            "levelCodeNo": "12.120251",
            "hazardsName": "乙酸酐",
            "cas": "108-24-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "309",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120252",
            "levelCodeNo": "12.120252",
            "hazardsName": "丙酸",
            "cas": "79-09-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "310",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120253",
            "levelCodeNo": "12.120253",
            "hazardsName": "对苯二甲酸",
            "cas": "100-21-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "311",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120254",
            "levelCodeNo": "12.120254",
            "hazardsName": "氟乙酸钠",
            "cas": "62-74-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "312",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120255",
            "levelCodeNo": "12.120255",
            "hazardsName": "甲基丙烯酸",
            "cas": "79-41-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "313",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120256",
            "levelCodeNo": "12.120256",
            "hazardsName": "甲酸",
            "cas": "64-18-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "314",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120257",
            "levelCodeNo": "12.120257",
            "hazardsName": "羟基乙酸",
            "cas": "79-14-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "315",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120258",
            "levelCodeNo": "12.120258",
            "hazardsName": "巯基乙酸",
            "cas": "68-11-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "316",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120259",
            "levelCodeNo": "12.120259",
            "hazardsName": "三甲基己二酸",
            "cas": "3937-59-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "317",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120260",
            "levelCodeNo": "12.120260",
            "hazardsName": "三氯乙酸",
            "cas": "76-03-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "318",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120261",
            "levelCodeNo": "12.120261",
            "hazardsName": "乙酸",
            "cas": "64-19-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "319",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120262",
            "levelCodeNo": "12.120262",
            "hazardsName": "正香草酸（高香草酸）",
            "cas": "306-08-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "320",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120263",
            "levelCodeNo": "12.120263",
            "hazardsName": "四氯化钛",
            "cas": "7550-45-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "321",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120264",
            "levelCodeNo": "12.120264",
            "hazardsName": "钽及其氧化物（按 Ta 计）",
            "cas": "7440-25-7（钽）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "322",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120265",
            "levelCodeNo": "12.120265",
            "hazardsName": "锑及其化合物（按 Sb 计）",
            "cas": "7440-36-0（锑）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "323",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120266",
            "levelCodeNo": "12.120266",
            "hazardsName": "五羰基铁（按 Fe 计）",
            "cas": "13463-40-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "324",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120267",
            "levelCodeNo": "12.120267",
            "hazardsName": "2-己酮（甲基正丁基甲酮）",
            "cas": "591-78-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "325",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120268",
            "levelCodeNo": "12.120268",
            "hazardsName": "3，5，5-三甲基-2-环己烯-1-酮（异佛尔酮）",
            "cas": "78-59-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "326",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120269",
            "levelCodeNo": "12.120269",
            "hazardsName": "丙酮",
            "cas": "67-64-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "327",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120270",
            "levelCodeNo": "12.120270",
            "hazardsName": "丁酮",
            "cas": "78-93-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "328",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120271",
            "levelCodeNo": "12.120271",
            "hazardsName": "二乙基甲酮",
            "cas": "96-22-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "329",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120272",
            "levelCodeNo": "12.120272",
            "hazardsName": "二异丁基甲酮",
            "cas": "108-83-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "330",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120273",
            "levelCodeNo": "12.120273",
            "hazardsName": "环己酮",
            "cas": "108-94-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "331",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120274",
            "levelCodeNo": "12.120274",
            "hazardsName": "环戊酮",
            "cas": "120-92-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "332",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120275",
            "levelCodeNo": "12.120275",
            "hazardsName": "六氟丙酮",
            "cas": "684-16-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "333",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120276",
            "levelCodeNo": "12.120276",
            "hazardsName": "氯丙酮",
            "cas": "78-95-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "334",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120277",
            "levelCodeNo": "12.120277",
            "hazardsName": "双丙酮醇",
            "cas": "123-42-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "335",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120278",
            "levelCodeNo": "12.120278",
            "hazardsName": "乙基另戊基甲酮（5-甲基-3-庚酮）",
            "cas": "541-85-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "336",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120279",
            "levelCodeNo": "12.120279",
            "hazardsName": "乙基戊基甲酮",
            "cas": "541-85-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "337",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120280",
            "levelCodeNo": "12.120280",
            "hazardsName": "乙烯酮",
            "cas": "463-51-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "338",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120281",
            "levelCodeNo": "12.120281",
            "hazardsName": "异亚丙基丙酮",
            "cas": "141-79-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "339",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120282",
            "levelCodeNo": "12.120282",
            "hazardsName": "铜及其化合物",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "340",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120283",
            "levelCodeNo": "12.120283",
            "hazardsName": "丙烷",
            "cas": "74-98-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "341",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120284",
            "levelCodeNo": "12.120284",
            "hazardsName": "环己烷",
            "cas": "110-82-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "342",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120285",
            "levelCodeNo": "12.120285",
            "hazardsName": "甲烷",
            "cas": "74-82-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "343",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120286",
            "levelCodeNo": "12.120286",
            "hazardsName": "壬烷",
            "cas": "111-84-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "344",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120287",
            "levelCodeNo": "12.120287",
            "hazardsName": "辛烷",
            "cas": "111-65-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "345",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120288",
            "levelCodeNo": "12.120288",
            "hazardsName": "正庚烷",
            "cas": "142-82-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "346",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120289",
            "levelCodeNo": "12.120289",
            "hazardsName": "戊烷（全部异构体）",
            "cas": "109-66-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "347",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120290",
            "levelCodeNo": "12.120290",
            "hazardsName": "2-乙氧基乙醇",
            "cas": "110-80-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "348",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120291",
            "levelCodeNo": "12.120291",
            "hazardsName": "2-甲氧基乙醇（甲氧基乙醇）",
            "cas": "109-86-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "349",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120292",
            "levelCodeNo": "12.120292",
            "hazardsName": "围涎树碱",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "350",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120293",
            "levelCodeNo": "12.120293",
            "hazardsName": "二硫化硒",
            "cas": "56093-45-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "351",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120294",
            "levelCodeNo": "12.120294",
            "hazardsName": "硒化氢（按 Se 计）",
            "cas": "7783-07-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "352",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120295",
            "levelCodeNo": "12.120295",
            "hazardsName": "钨及其不溶性化合物（按 W 计）",
            "cas": "7740-33-7（钨）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "353",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120296",
            "levelCodeNo": "12.120296",
            "hazardsName": "硒及其化合物（按 Se 计）（不包括六氟化硒、硒化氢）",
            "cas": "7782-49-2（硒）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "354",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120297",
            "levelCodeNo": "12.120297",
            "hazardsName": "二氧化锡（按 Sn 计）",
            "cas": "1332-29-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "355",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120298",
            "levelCodeNo": "12.120298",
            "hazardsName": "N,N-二甲基乙酰胺",
            "cas": "127-19-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "356",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120299",
            "levelCodeNo": "12.120299",
            "hazardsName": "N-3,4二氯苯基丙酰胺（敌稗）",
            "cas": "709-98-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "357",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120300",
            "levelCodeNo": "12.120300",
            "hazardsName": "氟乙酰胺",
            "cas": "640-19-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "358",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120301",
            "levelCodeNo": "12.120301",
            "hazardsName": "己内酰胺",
            "cas": "105-60-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "359",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120302",
            "levelCodeNo": "12.120302",
            "hazardsName": "奥克托今（环四次甲基四硝胺）",
            "cas": "2691-41-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "360",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120303",
            "levelCodeNo": "12.120303",
            "hazardsName": "环三次甲基三硝胺（黑索今）",
            "cas": "121-82-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "361",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120304",
            "levelCodeNo": "12.120304",
            "hazardsName": "硝化甘油",
            "cas": "55-63-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "362",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120305",
            "levelCodeNo": "12.120305",
            "hazardsName": "氯化锌烟",
            "cas": "7646-85-7（氯化锌）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "363",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120306",
            "levelCodeNo": "12.120306",
            "hazardsName": "氧化锌",
            "cas": "1314-13-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "364",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120307",
            "levelCodeNo": "12.120307",
            "hazardsName": "溴化氢（氢溴酸）",
            "cas": "10035-10-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "365",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120308",
            "levelCodeNo": "12.120308",
            "hazardsName": "臭氧",
            "cas": "10028-15-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "366",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120309",
            "levelCodeNo": "12.120309",
            "hazardsName": "过氧化氢",
            "cas": "7722-84-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "367",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120310",
            "levelCodeNo": "12.120310",
            "hazardsName": "钾盐镁矾",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "368",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120311",
            "levelCodeNo": "12.120311",
            "hazardsName": "丙烯基芥子油",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "369",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120312",
            "levelCodeNo": "12.120312",
            "hazardsName": "多次甲基多苯基异氰酸酯",
            "cas": "57029-46-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "370",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120313",
            "levelCodeNo": "12.120313",
            "hazardsName": "二苯基甲烷二异氰酸酯",
            "cas": "101-68-8",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "371",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120314",
            "levelCodeNo": "12.120314",
            "hazardsName": "甲苯 -2,4- 二异氰酸酯（TDI）",
            "cas": "584-84-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "372",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120315",
            "levelCodeNo": "12.120315",
            "hazardsName": "1,6-己二异氰酸酯（六亚甲基二异氰酸酯（HDI））",
            "cas": "822-06-0",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "373",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120316",
            "levelCodeNo": "12.120316",
            "hazardsName": "萘二异氰酸酯（NDI）",
            "cas": "3173-72-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "374",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120317",
            "levelCodeNo": "12.120317",
            "hazardsName": "异佛尔酮二异氰酸酯",
            "cas": "4098-71-9",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "375",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120318",
            "levelCodeNo": "12.120318",
            "hazardsName": "异氰酸甲酯",
            "cas": "624-83-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "376",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120319",
            "levelCodeNo": "12.120319",
            "hazardsName": "氧化银",
            "cas": "20667-12-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "377",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120320",
            "levelCodeNo": "12.120320",
            "hazardsName": "甲氧氯",
            "cas": "72-43-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "378",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120321",
            "levelCodeNo": "12.120321",
            "hazardsName": "2-氨基吡啶",
            "cas": "504-29-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "379",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120322",
            "levelCodeNo": "12.120322",
            "hazardsName": "N-乙基吗啉",
            "cas": "100-74-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "380",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120323",
            "levelCodeNo": "12.120323",
            "hazardsName": "吖啶",
            "cas": "260-94-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "381",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120324",
            "levelCodeNo": "12.120324",
            "hazardsName": "苯绕蒽酮",
            "cas": "82-05-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "382",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120325",
            "levelCodeNo": "12.120325",
            "hazardsName": "吡啶",
            "cas": "110-86-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "383",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120326",
            "levelCodeNo": "12.120326",
            "hazardsName": "二噁烷",
            "cas": "123-91-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "384",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120327",
            "levelCodeNo": "12.120327",
            "hazardsName": "呋喃",
            "cas": "110-00-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "385",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120328",
            "levelCodeNo": "12.120328",
            "hazardsName": "吗啉 ",
            "cas": "110-91-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "386",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120329",
            "levelCodeNo": "12.120329",
            "hazardsName": "四氢呋喃",
            "cas": "109-99-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "387",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120330",
            "levelCodeNo": "12.120330",
            "hazardsName": "茚",
            "cas": "95-13-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "388",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120331",
            "levelCodeNo": "12.120331",
            "hazardsName": "四氢化锗",
            "cas": "7782-65-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "389",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120332",
            "levelCodeNo": "12.120332",
            "hazardsName": "二乙烯二胺（哌嗪）",
            "cas": "110-85-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "390",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120333",
            "levelCodeNo": "12.120333",
            "hazardsName": "1,6-己二胺",
            "cas": "124-09-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "391",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120334",
            "levelCodeNo": "12.120334",
            "hazardsName": "二甲胺",
            "cas": "124-40-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "392",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120335",
            "levelCodeNo": "12.120335",
            "hazardsName": "二乙烯三胺（二乙撑三胺）",
            "cas": "111-40-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "393",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120336",
            "levelCodeNo": "12.120336",
            "hazardsName": "二异丙胺基氯乙烷",
            "cas": "96-79-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "394",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120337",
            "levelCodeNo": "12.120337",
            "hazardsName": "环己胺",
            "cas": "108-91-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "395",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120338",
            "levelCodeNo": "12.120338",
            "hazardsName": "氯乙基胺",
            "cas": "689-98-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "396",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120339",
            "levelCodeNo": "12.120339",
            "hazardsName": "三乙烯四胺（三乙撑四胺）",
            "cas": "112-24-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "397",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120340",
            "levelCodeNo": "12.120340",
            "hazardsName": "烯丙胺",
            "cas": "107-11-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "398",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120341",
            "levelCodeNo": "12.120341",
            "hazardsName": "乙胺",
            "cas": "75-04-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "399",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120342",
            "levelCodeNo": "12.120342",
            "hazardsName": "乙二胺（乙烯二胺，EDA）",
            "cas": "107-15-3",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "400",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120343",
            "levelCodeNo": "12.120343",
            "hazardsName": "异丙胺",
            "cas": "75-31-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "401",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120344",
            "levelCodeNo": "12.120344",
            "hazardsName": "正丁胺",
            "cas": "109-73-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "402",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120345",
            "levelCodeNo": "12.120345",
            "hazardsName": "1,1-二氯-1-硝基乙烷",
            "cas": "594-72-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "403",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120346",
            "levelCodeNo": "12.120346",
            "hazardsName": "1-硝基丙烷（硝基丙烷）",
            "cas": "25322-01-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "404",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120347",
            "levelCodeNo": "12.120347",
            "hazardsName": "氯化苦（三氯硝基甲烷）",
            "cas": "76-06-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "405",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120348",
            "levelCodeNo": "12.120348",
            "hazardsName": "硝基甲烷",
            "cas": "75-52-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "406",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120349",
            "levelCodeNo": "12.120349",
            "hazardsName": "硝基乙烷",
            "cas": "79-24-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "407",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120350",
            "levelCodeNo": "12.120350",
            "hazardsName": "1,3-二甲基丁基乙酸酯（仲乙酸己酯、乙酸仲己酯）",
            "cas": "108-84-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "408",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120351",
            "levelCodeNo": "12.120351",
            "hazardsName": "2-甲氧基乙基乙酸酯",
            "cas": "110-49-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "409",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120352",
            "levelCodeNo": "12.120352",
            "hazardsName": "2-乙氧基乙基乙酸酯",
            "cas": "111-15-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "410",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120353",
            "levelCodeNo": "12.120353",
            "hazardsName": "乳酸正丁酯",
            "cas": "138-22-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "411",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120354",
            "levelCodeNo": "12.120354",
            "hazardsName": "丙烯酸甲酯",
            "cas": "96-33-3",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "412",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120355",
            "levelCodeNo": "12.120355",
            "hazardsName": "丙烯酸正丁酯",
            "cas": "141-32-2",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "413",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120356",
            "levelCodeNo": "12.120356",
            "hazardsName": "甲基丙烯酸甲酯（异丁烯酸甲酯）",
            "cas": "80-62-6",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "414",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120357",
            "levelCodeNo": "12.120357",
            "hazardsName": "甲基丙烯酸缩水甘油酯",
            "cas": "106-91-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "415",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120358",
            "levelCodeNo": "12.120358",
            "hazardsName": "甲酸丁酯",
            "cas": "592-84-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "416",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120359",
            "levelCodeNo": "12.120359",
            "hazardsName": "甲酸甲酯",
            "cas": "107-31-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "417",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120360",
            "levelCodeNo": "12.120360",
            "hazardsName": "甲酸乙酯",
            "cas": "109-94-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "418",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120361",
            "levelCodeNo": "12.120361",
            "hazardsName": "氯甲酸甲酯",
            "cas": "79-22-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "419",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120362",
            "levelCodeNo": "12.120362",
            "hazardsName": "氯甲酸三氯甲酯（双光气）",
            "cas": "503-38-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "420",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120363",
            "levelCodeNo": "12.120363",
            "hazardsName": "三氟甲基次氟酸酯",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "421",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120364",
            "levelCodeNo": "12.120364",
            "hazardsName": "亚硝酸乙酯",
            "cas": "109-95-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "422",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120365",
            "levelCodeNo": "12.120365",
            "hazardsName": "乙二醇二硝酸酯",
            "cas": "628-96-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "423",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120366",
            "levelCodeNo": "12.120366",
            "hazardsName": "乙基硫代磺酸乙酯",
            "cas": "682-91-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "424",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120367",
            "levelCodeNo": "12.120367",
            "hazardsName": "乙酸苄酯",
            "cas": "140-11-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "425",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120368",
            "levelCodeNo": "12.120368",
            "hazardsName": "乙酸丙酯",
            "cas": "109-60-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "426",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120369",
            "levelCodeNo": "12.120369",
            "hazardsName": "乙酸丁酯",
            "cas": "123-86-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "427",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120370",
            "levelCodeNo": "12.120370",
            "hazardsName": "乙酸甲酯",
            "cas": "79-20-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "428",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120371",
            "levelCodeNo": "12.120371",
            "hazardsName": "乙酸戊酯（全部异构体）",
            "cas": "628-63-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "429",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120372",
            "levelCodeNo": "12.120372",
            "hazardsName": "乙酸乙烯酯",
            "cas": "108-05-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "430",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120373",
            "levelCodeNo": "12.120373",
            "hazardsName": "乙酸乙酯",
            "cas": "141-78-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "431",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120374",
            "levelCodeNo": "12.120374",
            "hazardsName": "乙酸异丙酯",
            "cas": "108-21-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "432",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120375",
            "levelCodeNo": "12.120375",
            "hazardsName": "酸雾或酸酐",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "433",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120376",
            "levelCodeNo": "12.120376",
            "hazardsName": "致喘物",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "434",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120377",
            "levelCodeNo": "12.120377",
            "hazardsName": "苯胺",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "435",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120378",
            "levelCodeNo": "12.120378",
            "hazardsName": "六价铬化合物",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "436",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120380",
            "levelCodeNo": "12.120380",
            "hazardsName": "砷",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "437",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120382",
            "levelCodeNo": "12.120382",
            "hazardsName": "硝酸",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "438",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120383",
            "levelCodeNo": "12.120383",
            "hazardsName": "硫酸",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "439",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120384",
            "levelCodeNo": "12.120384",
            "hazardsName": "其他化学因素",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "440",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "13",
            "levelCodeNo": "13",
            "hazardsName": "物理因素",
            "hazardNature": "0",
            "ifEnable": true
        },
        {
            "rid": "441",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130001",
            "levelCodeNo": "13.130001",
            "hazardsName": "噪声",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "442",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130002",
            "levelCodeNo": "13.130002",
            "hazardsName": "高温",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "443",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130003",
            "levelCodeNo": "13.130003",
            "hazardsName": "低气压",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "444",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130004",
            "levelCodeNo": "13.130004",
            "hazardsName": "高气压",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "445",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130005",
            "levelCodeNo": "13.130005",
            "hazardsName": "高原低氧",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "446",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130006",
            "levelCodeNo": "13.130006",
            "hazardsName": "振动",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "447",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130007",
            "levelCodeNo": "13.130007",
            "hazardsName": "激光",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "448",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130008",
            "levelCodeNo": "13.130008",
            "hazardsName": "低温",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "449",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130009",
            "levelCodeNo": "13.130009",
            "hazardsName": "微波",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "450",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130010",
            "levelCodeNo": "13.130010",
            "hazardsName": "紫外辐射(紫外线)",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "451",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130011",
            "levelCodeNo": "13.130011",
            "hazardsName": "红外线",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "452",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130012",
            "levelCodeNo": "13.130012",
            "hazardsName": "工频电磁场（工频辐射）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "453",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130013",
            "levelCodeNo": "13.130013",
            "hazardsName": "高频电磁场（高频辐射）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "454",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130014",
            "levelCodeNo": "13.130014",
            "hazardsName": "超高频电磁场（超高频辐射）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "455",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130016",
            "levelCodeNo": "13.130016",
            "hazardsName": "其他物理因素",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "456",
            "ifDelete": false,
            "hazardsSort": 4,
            "codeNo": "14",
            "levelCodeNo": "14",
            "hazardsName": "放射因素",
            "hazardNature": "0",
            "ifEnable": true
        },
        {
            "rid": "457",
            "ifDelete": false,
            "hazardsSort": 4,
            "codeNo": "140001",
            "levelCodeNo": "14.140001",
            "hazardsName": "α射线",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "14"
        },
        {
            "rid": "458",
            "ifDelete": false,
            "hazardsSort": 4,
            "codeNo": "140002",
            "levelCodeNo": "14.140002",
            "hazardsName": "β射线",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "14"
        },
        {
            "rid": "459",
            "ifDelete": false,
            "hazardsSort": 4,
            "codeNo": "140003",
            "levelCodeNo": "14.140003",
            "hazardsName": "X射线",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "14"
        },
        {
            "rid": "460",
            "ifDelete": false,
            "hazardsSort": 4,
            "codeNo": "140004",
            "levelCodeNo": "14.140004",
            "hazardsName": "γ射线",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "14"
        },
        {
            "rid": "461",
            "ifDelete": false,
            "hazardsSort": 4,
            "codeNo": "140005",
            "levelCodeNo": "14.140005",
            "hazardsName": "中子",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "14"
        },
        {
            "rid": "462",
            "ifDelete": false,
            "hazardsSort": 4,
            "codeNo": "140006",
            "levelCodeNo": "14.140006",
            "hazardsName": "氡及其短寿命子体",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "14"
        },
        {
            "rid": "463",
            "ifDelete": false,
            "hazardsSort": 4,
            "codeNo": "140008",
            "levelCodeNo": "14.140008",
            "hazardsName": "其他放射性因素",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "14"
        },
        {
            "rid": "464",
            "ifDelete": false,
            "hazardsSort": 5,
            "codeNo": "15",
            "levelCodeNo": "15",
            "hazardsName": "生物因素",
            "hazardNature": "0",
            "ifEnable": true
        },
        {
            "rid": "465",
            "ifDelete": false,
            "hazardsSort": 5,
            "codeNo": "150001",
            "levelCodeNo": "15.150001",
            "hazardsName": "艾滋病病毒",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "15"
        },
        {
            "rid": "466",
            "ifDelete": false,
            "hazardsSort": 5,
            "codeNo": "150002",
            "levelCodeNo": "15.150002",
            "hazardsName": "布鲁菌属",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "15"
        },
        {
            "rid": "467",
            "ifDelete": false,
            "hazardsSort": 5,
            "codeNo": "150003",
            "levelCodeNo": "15.150003",
            "hazardsName": "伯氏疏螺旋体",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "15"
        },
        {
            "rid": "468",
            "ifDelete": false,
            "hazardsSort": 5,
            "codeNo": "150004",
            "levelCodeNo": "15.150004",
            "hazardsName": "森林脑炎病毒",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "15"
        },
        {
            "rid": "469",
            "ifDelete": false,
            "hazardsSort": 5,
            "codeNo": "150005",
            "levelCodeNo": "15.150005",
            "hazardsName": "炭疽杆菌",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "15"
        },
        {
            "rid": "470",
            "ifDelete": false,
            "hazardsSort": 5,
            "codeNo": "150006",
            "levelCodeNo": "15.150006",
            "hazardsName": "其他生物因素",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "15"
        },
        {
            "rid": "471",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "16",
            "levelCodeNo": "16",
            "hazardsName": "其他因素",
            "hazardNature": "0",
            "ifEnable": true
        },
        {
            "rid": "472",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160001",
            "levelCodeNo": "16.160001",
            "hazardsName": "金属烟",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "473",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160002",
            "levelCodeNo": "16.160002",
            "hazardsName": "井下不良作业条件",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "474",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160003",
            "levelCodeNo": "16.160003",
            "hazardsName": "刮研作业",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "475",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160004",
            "levelCodeNo": "16.160004",
            "hazardsName": "电工作业",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "476",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160005",
            "levelCodeNo": "16.160005",
            "hazardsName": "高处作业",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "477",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160006",
            "levelCodeNo": "16.160006",
            "hazardsName": "压力容器作业",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "478",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160007",
            "levelCodeNo": "16.160007",
            "hazardsName": "结核病防治工作",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "479",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160008",
            "levelCodeNo": "16.160008",
            "hazardsName": "肝炎病防治工作",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "480",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160009",
            "levelCodeNo": "16.160009",
            "hazardsName": "职业机动车驾驶作业",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "481",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160010",
            "levelCodeNo": "16.160010",
            "hazardsName": "视屏作业",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "482",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160011",
            "levelCodeNo": "16.160011",
            "hazardsName": "高原作业",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "483",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160012",
            "levelCodeNo": "16.160012",
            "hazardsName": "航空作业",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "484",
            "ifDelete": false,
            "hazardsSort": 6,
            "codeNo": "160013",
            "levelCodeNo": "16.160013",
            "hazardsName": "其它职业病危害因素",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "16"
        },
        {
            "rid": "485",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120385",
            "levelCodeNo": "12.120385",
            "hazardsName": "氨基氰",
            "cas": "420-04-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "486",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120386",
            "levelCodeNo": "12.120386",
            "hazardsName": "百菌清",
            "cas": "1897-45-6",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "487",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120387",
            "levelCodeNo": "12.120387",
            "hazardsName": "倍硫磷",
            "cas": "55-38-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "488",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120388",
            "levelCodeNo": "12.120388",
            "hazardsName": "苯醌",
            "cas": "106-51-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "489",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120389",
            "levelCodeNo": "12.120389",
            "hazardsName": "苯硫磷",
            "cas": "2104-64-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "490",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120390",
            "levelCodeNo": "12.120390",
            "hazardsName": "丙酮氰醇（按 CN 计）",
            "cas": "75-86-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "491",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120391",
            "levelCodeNo": "12.120391",
            "hazardsName": "丙烯腈",
            "cas": "107-13-1",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "492",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120392",
            "levelCodeNo": "12.120392",
            "hazardsName": "丙烯菊酯",
            "cas": "584-79-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "493",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120393",
            "levelCodeNo": "12.120393",
            "hazardsName": "丙烯酸",
            "cas": "79-10-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "494",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120394",
            "levelCodeNo": "12.120394",
            "hazardsName": "草甘膦",
            "cas": "1071-83-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "495",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120395",
            "levelCodeNo": "12.120395",
            "hazardsName": "草酸",
            "cas": "144-62-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "496",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120396",
            "levelCodeNo": "12.120396",
            "hazardsName": "抽余油（60 ℃~220 ℃）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "497",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120397",
            "levelCodeNo": "12.120397",
            "hazardsName": "o,o-二甲基-S-（甲基氨基甲酰甲基）二硫代磷酸酯（乐果）",
            "cas": "60-51-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "498",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120398",
            "levelCodeNo": "12.120398",
            "hazardsName": "O,O-二甲基-（2,2,2-三氯-1 羟基乙基）磷酸酯（敌百虫）",
            "cas": "52-68-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "499",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120399",
            "levelCodeNo": "12.120399",
            "hazardsName": "N-3,4-二氯苯基-N`,N`-二甲基脲（敌草隆）",
            "cas": "330-54-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "500",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120400",
            "levelCodeNo": "12.120400",
            "hazardsName": "2,4-二氯苯氧基乙酸（2,4-滴）",
            "cas": "94-75-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "501",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120401",
            "levelCodeNo": "12.120401",
            "hazardsName": "二氯二苯基三氯乙烷（(滴滴涕，DDT)",
            "cas": "50-29-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "502",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120402",
            "levelCodeNo": "12.120402",
            "hazardsName": "碲及其化合物（不含碲化氢）（按 Te 计）",
            "cas": "13494-80-9（Te）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "503",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120403",
            "levelCodeNo": "12.120403",
            "hazardsName": "碘",
            "cas": "7553-56-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "504",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120404",
            "levelCodeNo": "12.120404",
            "hazardsName": "碘仿",
            "cas": "75-47-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "505",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120406",
            "levelCodeNo": "12.120406",
            "hazardsName": "叠氮酸蒸气",
            "cas": "7782-79-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "506",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120407",
            "levelCodeNo": "12.120407",
            "hazardsName": "叠氮化钠",
            "cas": "26628-22-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "507",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120408",
            "levelCodeNo": "12.120408",
            "hazardsName": "2-丁氧基乙醇",
            "cas": "111-76-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "508",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120409",
            "levelCodeNo": "12.120409",
            "hazardsName": "毒死蜱",
            "cas": "2921-88-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "509",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120410",
            "levelCodeNo": "12.120410",
            "hazardsName": "对二氯苯（二氯苯）",
            "cas": "106-46-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "510",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120411",
            "levelCodeNo": "12.120411",
            "hazardsName": "对硫磷",
            "cas": "56-38-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "511",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120412",
            "levelCodeNo": "12.120412",
            "hazardsName": "对硝基苯胺",
            "cas": "100-01-6",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "512",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120413",
            "levelCodeNo": "12.120413",
            "hazardsName": "对硝基氯苯",
            "cas": "100-00-5",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "513",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120414",
            "levelCodeNo": "12.120414",
            "hazardsName": "多次甲基多苯基多异氰酸酯",
            "cas": "57029-46-6",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "514",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120415",
            "levelCodeNo": "12.120415",
            "hazardsName": "二苯胺",
            "cas": "122-39-4",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "515",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120416",
            "levelCodeNo": "12.120416",
            "hazardsName": "二丙二醇甲醚（2-甲氧基甲乙氧基丙醇）",
            "cas": "34590-94-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "516",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120417",
            "levelCodeNo": "12.120417",
            "hazardsName": "二丙酮醇",
            "cas": "123-42-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "517",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120418",
            "levelCodeNo": "12.120418",
            "hazardsName": "二噁英类化合物",
            "cas": "1746-01-6",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "518",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120419",
            "levelCodeNo": "12.120419",
            "hazardsName": "二氟氯甲烷",
            "cas": "75-45-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "519",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120420",
            "levelCodeNo": "12.120420",
            "hazardsName": "N,N-二甲基苯胺",
            "cas": "121-69-7",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "520",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120421",
            "levelCodeNo": "12.120421",
            "hazardsName": "二甲氧基甲烷",
            "cas": "109-87-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "521",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120422",
            "levelCodeNo": "12.120422",
            "hazardsName": "1,3-二氯丙醇",
            "cas": "96-23-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "522",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120423",
            "levelCodeNo": "12.120423",
            "hazardsName": "1,2-二氯乙烷",
            "cas": "107-06-2",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "524",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120425",
            "levelCodeNo": "12.120425",
            "hazardsName": "二硝基苯(全部异构体)",
            "cas": "25154-54-5;528-29-0;",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "525",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120426",
            "levelCodeNo": "12.120426",
            "hazardsName": "二硝基甲苯",
            "cas": "25321-14-6",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "526",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120427",
            "levelCodeNo": "12.120427",
            "hazardsName": "4,6-二硝基邻甲酚",
            "cas": "534-52-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "527",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120428",
            "levelCodeNo": "12.120428",
            "hazardsName": "2,4-二硝基氯苯",
            "cas": "97-00-7",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "528",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120429",
            "levelCodeNo": "12.120429",
            "hazardsName": "氮氧化物（一氧化氮和、二氧化氮）",
            "cas": "10102-43-9;10102-44-",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "529",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120430",
            "levelCodeNo": "12.120430",
            "hazardsName": "二氧化碳",
            "cas": "124-38-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "530",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120431",
            "levelCodeNo": "12.120431",
            "hazardsName": "二异氰酸甲苯酯（TDI）",
            "cas": "584-84-9",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "531",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120432",
            "levelCodeNo": "12.120432",
            "hazardsName": "二月桂酸二丁基锡",
            "cas": "77-58-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "532",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120435",
            "levelCodeNo": "12.120435",
            "hazardsName": "氟化氢（按 F 计）",
            "cas": "7664-39-3",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "533",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120436",
            "levelCodeNo": "12.120436",
            "hazardsName": "汞-金属汞（蒸气）",
            "cas": "7439-97-6",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "534",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120437",
            "levelCodeNo": "12.120437",
            "hazardsName": "过氧化甲乙酮",
            "cas": "1338-23-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "535",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120438",
            "levelCodeNo": "12.120438",
            "hazardsName": "黄磷",
            "cas": "7723-14-0",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "536",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120439",
            "levelCodeNo": "12.120439",
            "hazardsName": "甲拌磷",
            "cas": "298-02-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "537",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120440",
            "levelCodeNo": "12.120440",
            "hazardsName": "N-甲苯胺，O-甲苯胺",
            "cas": "100-61-8，95-53-4",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "538",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120442",
            "levelCodeNo": "12.120442",
            "hazardsName": "甲基丙烯腈",
            "cas": "126-98-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "539",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120443",
            "levelCodeNo": "12.120443",
            "hazardsName": "甲基内吸磷",
            "cas": "8022-00-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "540",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120444",
            "levelCodeNo": "12.120444",
            "hazardsName": "18-甲基炔诺酮（炔诺孕酮）",
            "cas": "6533-00-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "541",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120445",
            "levelCodeNo": "12.120445",
            "hazardsName": "甲基叔丁基醚",
            "cas": "1634-04-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "542",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120446",
            "levelCodeNo": "12.120446",
            "hazardsName": "甲乙酮（2-丁酮）",
            "cas": "78-93-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "543",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120447",
            "levelCodeNo": "12.120447",
            "hazardsName": "久效磷",
            "cas": "6923-22-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "544",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120448",
            "levelCodeNo": "12.120448",
            "hazardsName": "考的松",
            "cas": "53-06-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "545",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120449",
            "levelCodeNo": "12.120449",
            "hazardsName": "邻二氯苯",
            "cas": "95-50-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "546",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120450",
            "levelCodeNo": "12.120450",
            "hazardsName": "邻氯苄叉丙二腈",
            "cas": "2698-41-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "547",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120451",
            "levelCodeNo": "12.120451",
            "hazardsName": "磷胺",
            "cas": "13171-21-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "548",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120452",
            "levelCodeNo": "12.120452",
            "hazardsName": "磷化氢",
            "cas": "7803-51-2",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "549",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120453",
            "levelCodeNo": "12.120453",
            "hazardsName": "磷酸",
            "cas": "7664-38-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "550",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120454",
            "levelCodeNo": "12.120454",
            "hazardsName": "硫酰氟",
            "cas": "2699-79-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "551",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120455",
            "levelCodeNo": "12.120455",
            "hazardsName": "六氟丙烯",
            "cas": "116-15-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "552",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120456",
            "levelCodeNo": "12.120456",
            "hazardsName": "六六六（六氯环已烷）",
            "cas": "608-73-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "553",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120457",
            "levelCodeNo": "12.120457",
            "hazardsName": "γ-六六六（γ-六氯环己烷）",
            "cas": "58-89-9",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "554",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120458",
            "levelCodeNo": "12.120458",
            "hazardsName": "氯化汞（升汞）",
            "cas": "7487-94-7",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "555",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120459",
            "levelCodeNo": "12.120459",
            "hazardsName": "氯化氰",
            "cas": "506-77-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "556",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120460",
            "levelCodeNo": "12.120460",
            "hazardsName": "氯甲甲醚",
            "cas": "107-30-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "557",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120461",
            "levelCodeNo": "12.120461",
            "hazardsName": "氯乙烯",
            "cas": "75-01-4",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "558",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120462",
            "levelCodeNo": "12.120462",
            "hazardsName": "马拉硫磷",
            "cas": "121-75-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "559",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120463",
            "levelCodeNo": "12.120463",
            "hazardsName": "钼及其化合物（按 Mo 计）",
            "cas": "7439-98-（Mo）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "560",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120464",
            "levelCodeNo": "12.120464",
            "hazardsName": "内吸磷",
            "cas": "8065-48-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "561",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120465",
            "levelCodeNo": "12.120465",
            "hazardsName": "尿素",
            "cas": "57-13-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "562",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120466",
            "levelCodeNo": "12.120466",
            "hazardsName": "氢化锂",
            "cas": "7580-67-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "563",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120467",
            "levelCodeNo": "12.120467",
            "hazardsName": "氢氧化钾",
            "cas": "1310-58-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "564",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120468",
            "levelCodeNo": "12.120468",
            "hazardsName": "氰化氢（按CN计）",
            "cas": "74-90-8",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "565",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120469",
            "levelCodeNo": "12.120469",
            "hazardsName": "氰化物（按 CN 计）",
            "cas": "57-12-5",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "566",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120470",
            "levelCodeNo": "12.120470",
            "hazardsName": "氰戊菊酯",
            "cas": "51630-58-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "567",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120471",
            "levelCodeNo": "12.120471",
            "hazardsName": "全氟异丁烯",
            "cas": "382-21-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "568",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120472",
            "levelCodeNo": "12.120472",
            "hazardsName": "三氟甲基次氟化物",
            "cas": "373-91-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "569",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120473",
            "levelCodeNo": "12.120473",
            "hazardsName": "三氯化磷",
            "cas": "7719-12-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "570",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120474",
            "levelCodeNo": "12.120474",
            "hazardsName": "三氯硫磷",
            "cas": "3982-91-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "571",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120475",
            "levelCodeNo": "12.120475",
            "hazardsName": "三氯氧磷",
            "cas": "10025-87-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "572",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120476",
            "levelCodeNo": "12.120476",
            "hazardsName": "三溴甲烷",
            "cas": "75-25-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "573",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120477",
            "levelCodeNo": "12.120477",
            "hazardsName": "三氧化铬、铬酸盐、重铬酸盐（按Cr计）",
            "cas": "1333-82-0 ；18540-29-",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "574",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120478",
            "levelCodeNo": "12.120478",
            "hazardsName": "三乙基氯化锡",
            "cas": "994-31-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "575",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120479",
            "levelCodeNo": "12.120479",
            "hazardsName": "杀螟松",
            "cas": "122-14-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "576",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120480",
            "levelCodeNo": "12.120480",
            "hazardsName": "十溴联苯醚",
            "cas": "1163-19-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "577",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120481",
            "levelCodeNo": "12.120481",
            "hazardsName": "双（巯基乙酸）二辛基锡",
            "cas": "26401-97-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "578",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120482",
            "levelCodeNo": "12.120482",
            "hazardsName": "双酚 A",
            "cas": "80-05-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "579",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120483",
            "levelCodeNo": "12.120483",
            "hazardsName": "双硫醒",
            "cas": "97-77-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "580",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120484",
            "levelCodeNo": "12.120484",
            "hazardsName": "四氢化硅",
            "cas": "7803-62-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "581",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120485",
            "levelCodeNo": "12.120485",
            "hazardsName": "松节油",
            "cas": "8006-64-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "582",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120486",
            "levelCodeNo": "12.120486",
            "hazardsName": "羰基氟",
            "cas": "353-50-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "583",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120487",
            "levelCodeNo": "12.120487",
            "hazardsName": "铜及其化合物（按 Cu 计）",
            "cas": "7440-50-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "584",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120488",
            "levelCodeNo": "12.120488",
            "hazardsName": "五硫化二磷",
            "cas": "1314 -80-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "585",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120489",
            "levelCodeNo": "12.120489",
            "hazardsName": "五氧化二磷",
            "cas": "1314-56-3",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "586",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120490",
            "levelCodeNo": "12.120490",
            "hazardsName": "纤维素",
            "cas": "9004-34-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "587",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120491",
            "levelCodeNo": "12.120491",
            "hazardsName": "硝基苯",
            "cas": "98-95-3",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "588",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120492",
            "levelCodeNo": "12.120492",
            "hazardsName": "2-硝基丙烷",
            "cas": "79-46-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "589",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120493",
            "levelCodeNo": "12.120493",
            "hazardsName": "硝基甲苯（全部异构体）",
            "cas": "88-72-2;99-08-1;99-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "590",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120494",
            "levelCodeNo": "12.120494",
            "hazardsName": "溴",
            "cas": "7726-95-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "591",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120495",
            "levelCodeNo": "12.120495",
            "hazardsName": "溴氰菊酯",
            "cas": "52918-63-5",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "592",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120496",
            "levelCodeNo": "12.120496",
            "hazardsName": "溴鼠灵",
            "cas": "56073-10-0",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "593",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120497",
            "levelCodeNo": "12.120497",
            "hazardsName": "氧化镁烟",
            "cas": "1309-48-4",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "594",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120498",
            "levelCodeNo": "12.120498",
            "hazardsName": "氧乐果",
            "cas": "1113-02-6",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "595",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120499",
            "levelCodeNo": "12.120499",
            "hazardsName": "液化石油气",
            "cas": "68476-85-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "596",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120500",
            "levelCodeNo": "12.120500",
            "hazardsName": "乙酐（乙酸酐）",
            "cas": "108-24-7",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "597",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120501",
            "levelCodeNo": "12.120501",
            "hazardsName": "乙腈",
            "cas": "75-05-8",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "598",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120502",
            "levelCodeNo": "12.120502",
            "hazardsName": "乙酰甲胺磷",
            "cas": "30560-19-1",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "599",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120503",
            "levelCodeNo": "12.120503",
            "hazardsName": "乙酰水杨酸（阿司匹林）",
            "cas": "50-78-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "600",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120504",
            "levelCodeNo": "12.120504",
            "hazardsName": "钇及其化合物（按 Y 计）",
            "cas": "7440-65-5(Y)",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "601",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120505",
            "levelCodeNo": "12.120505",
            "hazardsName": "N-异丙基苯胺",
            "cas": "768-52-5",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "602",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120506",
            "levelCodeNo": "12.120506",
            "hazardsName": "异稻瘟净",
            "cas": "26087-47-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "603",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120507",
            "levelCodeNo": "12.120507",
            "hazardsName": "莠去津",
            "cas": "1912-24-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "604",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120508",
            "levelCodeNo": "12.120508",
            "hazardsName": "三甲基氯化锡",
            "cas": "1066-45-1",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "605",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110101",
            "levelCodeNo": "11.110101",
            "hazardsName": "酚醛树酯粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "606",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110102",
            "levelCodeNo": "11.110102",
            "hazardsName": "工业酶混合尘",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "607",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110103",
            "levelCodeNo": "11.110103",
            "hazardsName": "过氯酸铵粉尘",
            "cas": "7790-98-9",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "608",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110104",
            "levelCodeNo": "11.110104",
            "hazardsName": "凝聚 SiO2 粉尘",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "609",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110105",
            "levelCodeNo": "11.110105",
            "hazardsName": "人造矿物纤维绝热棉粉尘（玻璃棉、矿渣棉、岩棉）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "610",
            "ifDelete": false,
            "hazardsSort": 1,
            "codeNo": "110106",
            "levelCodeNo": "11.110106",
            "hazardsName": "洗衣粉混合尘",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "11"
        },
        {
            "rid": "611",
            "ifDelete": false,
            "hazardsSort": 5,
            "codeNo": "150007",
            "levelCodeNo": "15.150007",
            "hazardsName": "白僵蚕孢子",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "15"
        },
        {
            "rid": "612",
            "ifDelete": false,
            "hazardsSort": 5,
            "codeNo": "150008",
            "levelCodeNo": "15.150008",
            "hazardsName": "枯草杆菌蛋白酶",
            "cas": "1395-21-7；9014-01-1",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "15"
        },
        {
            "rid": "613",
            "ifDelete": false,
            "hazardsSort": 5,
            "codeNo": "150009",
            "levelCodeNo": "15.150009",
            "hazardsName": "工业酶",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "15"
        },
        {
            "rid": "614",
            "ifDelete": false,
            "hazardsSort": 3,
            "codeNo": "130015",
            "levelCodeNo": "13.130015",
            "hazardsName": "可见光",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "13"
        },
        {
            "rid": "615",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120509",
            "levelCodeNo": "12.120509",
            "hazardsName": "五氧化二钒烟尘",
            "cas": "1314-62-1",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "616",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120510",
            "levelCodeNo": "12.120510",
            "hazardsName": "可溶性镍化合物",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "617",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120511",
            "levelCodeNo": "12.120511",
            "hazardsName": "金属镍与难溶性镍化合物",
            "cas": "7440-02-0（Ni）",
            "hazardNature": "1",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "618",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120512",
            "levelCodeNo": "12.120512",
            "hazardsName": "三烷基锡",
            "cas": "993-16-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "619",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120513",
            "levelCodeNo": "12.120513",
            "hazardsName": "四烷基锡",
            "cas": "1461-25-2",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "620",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120514",
            "levelCodeNo": "12.120514",
            "hazardsName": "β萘胺",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "621",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120515",
            "levelCodeNo": "12.120515",
            "hazardsName": "钽及其化合物",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "622",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120516",
            "levelCodeNo": "12.120516",
            "hazardsName": "丙醇",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "623",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120517",
            "levelCodeNo": "12.120517",
            "hazardsName": "邻茴香胺",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "624",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120518",
            "levelCodeNo": "12.120518",
            "hazardsName": "磷化铝",
            "cas": "20859-73-8",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "625",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120520",
            "levelCodeNo": "12.120520",
            "hazardsName": "二苯亚甲基二异氰酸酯（MDI）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        },
        {
            "rid": "626",
            "ifDelete": false,
            "hazardsSort": 2,
            "codeNo": "120521",
            "levelCodeNo": "12.120521",
            "hazardsName": "四氯苯二酸酐（TCPA）",
            "hazardNature": "0",
            "ifEnable": true,
            "upcodeNo": "12"
        }
    ],
    "mess": "获取成功",
    "type": "00",
    "msSign": "04e990dcd8063829435496236911a5767b0ef75c40a84de77720311cbe966e7b956c95f00aca0e935ae4d22adbd24a6d8ec8bdd50265618f4ddebeecef265271a5f1a974b1e41a79e57cf26c6be861a7cdf56aeb2d74df9d39cbd22ad4e8470e2661784d304fe103a887eb2e78269fc7fb1d5e08e83caf1c27ec7e55daa19f0a0ba62d46b2636873d219b433fa6cba8198753a556004501dce1e9300200e80d97f"
}

export default test